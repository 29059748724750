import React from 'react';
import { Box, Typography } from '@material-ui/core';

import { useTranslation } from 'react-i18next';

export default function AllowCameraUseError() {
  const { t } = useTranslation(['app']);

  return (
    <React.Fragment>
      <Box fontWeight="fontWeightBold" m={1}>
        <Typography color={'error'} align={'center'}>
          {t('app:cameraPermissions', 'You need to allow camera permissions')}
        </Typography>
      </Box>
    </React.Fragment>
  );
}
