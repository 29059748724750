import React, { useContext, useEffect, useState } from 'react';
import { videoStreamStore } from '../../../stores/videoContext';
import VerificationContainer from '../container/index.js';
import AllowCameraUseError from '../../cameraPermissionsError';
import { videoWrapperStyles } from '../../cameraPreview/styles';

import { useVerification } from '../../../stores/verificationContext';
import { useTranslation } from 'react-i18next';
import textract from '../../../lib/textract';
import { Grid, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

type ReviewDocumentPhotoProps = {
  next: Function;
  prev: Function;
  retries: Number;
  maxRetries: Number;
};

type ErrorsType = { type: string; code: number; message: string };

export default function ReviewDocumentPhoto({
  next,
  prev,
  retries,
  maxRetries,
}: ReviewDocumentPhotoProps) {
  const { t } = useTranslation(['app']);

  const { videoStream, startVideoStream, videoStreamError } = useContext(
    videoStreamStore
  );

  const {
    verificationToken,
    firstName,
    middleName,
    lastName,
    selectedCountry,
    documentPhotoBlob,
  } = useVerification();
  let documentPhotoImg = '';

  if (documentPhotoBlob.size > 0 && documentPhotoBlob.type === 'image/jpeg') {
    documentPhotoImg = URL.createObjectURL(documentPhotoBlob);
  }

  const classes = videoWrapperStyles();

  const [hasBeenAnalysed, setHasBeenAnalysed] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [errors, setErrors] = useState<ErrorsType[]>([]);

  useEffect(() => {
    startVideoStream(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (documentPhotoBlob && !hasBeenAnalysed) {
      textract(verificationToken, documentPhotoBlob, {
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        country: selectedCountry ? selectedCountry.name : '',
      })
        .then((response) => response.data)
        .then((data) => {
          setErrors(data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsBusy(false);
        });

      setHasBeenAnalysed(true);
      setIsBusy(true);
    }
  }, [
    verificationToken,
    documentPhotoBlob,
    hasBeenAnalysed,
    isBusy,
    firstName,
    middleName,
    lastName,
    selectedCountry,
    setHasBeenAnalysed,
    setIsBusy,
    setErrors,
  ]);

  const goForward = () => {
    next();
  };

  const goBack = () => {
    prev();
  };

  let continueDisabled = !videoStream;
  let error: any = null;
  if (!isBusy && hasBeenAnalysed) {
    if (errors && errors.length > 0) {
      if (retries < maxRetries) {
        continueDisabled = true;
      }

      if (errors.some((error) => error.type === 'BLUR_FAIL')) {
        error = t(
          'app:errorBlurText',
          'Image you have taken is blurry. Please take another photo.'
        );
      } else if (
        errors.some((error) => error.type === 'GLARE_UNEVEN_ILLUMINATION')
      ) {
        error = t(
          'app:errorUnevenIlluminationText',
          'Image you have taken is unevenly illuminated. Please take another photo.'
        );
      } else if (errors.some((error) => error.type === 'GLARE_DETECTED')) {
        error = t(
          'app:errorGlareText',
          'We detected glare in the image you have taken. Please take another photo.'
        );
      } else {
        error = t(
          'app:errorNoDocumentDetected',
          "We couldn't detect a document in the image you have taken. Please take another photo"
        );
      }
      error = (
        <Grid
          container
          alignItems={'center'}
          alignContent={'center'}
          justify={'center'}
        >
          <Typography
            color={'error'}
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <CancelIcon style={{ marginRight: '1em' }} color={'error'} />
            {error}
          </Typography>
        </Grid>
      );
    }
  }

  return (
    <VerificationContainer
      loading={isBusy}
      loadingText={t('app:analyzingDocument', 'Analyzing document')}
      next={goForward}
      prev={goBack}
      prevButtonText={
        error
          ? t('app:retakePhoto', 'Retake photo')
          : t('app:noRetake', 'No, retake')
      }
      headerText={t(
        'app:reviewDocumentPhotoTitle',
        'Photo side of your identity document'
      )}
      buttonText={
        error && retries >= maxRetries
          ? t('app:continueAnyway', 'Continue anyway')
          : t('app:yesContinue', 'Yes, continue')
      }
      buttonDisabled={continueDisabled}
      buttonHidden={error && retries < maxRetries}
      stretch
    >
      {videoStreamError && <AllowCameraUseError />}
      {documentPhotoImg && (
        <React.Fragment>
          <img
            src={documentPhotoImg}
            className={classes.placeHolderImage}
            alt={''}
          />
        </React.Fragment>
      )}
      {error}
      {!isBusy && hasBeenAnalysed && error == null && (
        <Grid alignItems={'center'} alignContent={'center'}>
          <Typography>
            {t(
              'app:isTheDocumentFullyVisibleAndDataClearlyReadable',
              'Is the document fully visible and data clearly readable ?'
            )}
          </Typography>
        </Grid>
      )}
    </VerificationContainer>
  );
}
