import React, { createContext, useRef, useState } from 'react';
import {
  CameraPermissionsDenied,
  DeviceNotSupported,
  NoWebcam,
} from './errors';
import {
  cameraFacing,
  filterUniqueVideoDevices,
  getMediaConstrains,
  hasMediaRecorderAPI,
  hasUserMediasAPI,
  hasWebRtcApi,
  getMediaRecorderOptions,
  isiOS,
} from './utils';
import { useVerification } from '../verificationContext';

type InitialStateInterface = {
  videoStream: MediaStream | null;
  videoStreamError: Error | null;
  videoStreamLoading: Boolean;
  startVideoStream(
    shouldFaceUser?: boolean,
    shouldSaveRecording?: boolean | false,
    specificDevice?: MediaDeviceInfo | null
  ): void;
  stopVideoStream(): void;
  getRecordingBlob(): Blob;
  getSnapShot(video: HTMLVideoElement): Promise<Blob>;
  checkCompatibility(): Boolean;
  getAvailableDevices(devices: MediaDeviceInfo[]): MediaDeviceInfo[];
  switchCamera(shouldSaveRecording?: boolean | false): void;
  hasMultipleCameras(): boolean;
  isCameraFacingUser(): boolean;
  startRecording(videoStream: MediaStream): void;
  stopRecording(): void;
};

const initialState: InitialStateInterface = {
  videoStream: null,
  videoStreamError: null,
  videoStreamLoading: false,
  startVideoStream: () => {},
  stopVideoStream: () => {},
  getRecordingBlob: (): Blob => {
    return new Blob();
  },
  getSnapShot: (video): Promise<Blob> => {
    return new Promise(() => {});
  },
  checkCompatibility: () => {
    return false;
  },
  getAvailableDevices: (devices: MediaDeviceInfo[]): MediaDeviceInfo[] => {
    return [];
  },
  switchCamera: () => {},
  hasMultipleCameras: () => {
    return false;
  },
  isCameraFacingUser: () => {
    return false;
  },
  startRecording: () => {},
  stopRecording: () => {},
};

const videoStreamStore = createContext(initialState);
const { Provider } = videoStreamStore;

const photoSnapShotCanvas = {
  display: 'none',
};

const CanNotMakeSnapshotBlobError = Error('Snapshot Blob can not be build');

const VideoStreamProvider = ({ children }: { children: React.ReactNode }) => {
  const [videoStreamLoading, setVideoStreamLoading] = useState<boolean>(false);
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const { setVideoRecordingBlob } = useVerification();

  const [videoStreamError, setVideoStreamError] = useState<Error | null>(null);
  const recordedBlobsRef = useRef<Blob[]>([]);
  const photoSnapShotCanvasRef = useRef<HTMLCanvasElement | null>(null);
  const availableMediaDevices = useRef<MediaDeviceInfo[]>([]);
  const currentUserFacing = useRef<cameraFacing | null>();

  const getRecordingBlob = (): Blob => {
    return new Blob(recordedBlobsRef.current, {
      type: mediaRecorder?.mimeType,
    });
  };

  const handleRecordedData = (event: BlobEvent) => {
    if (event.data && event.data.size > 0) {
      recordedBlobsRef.current.push(event.data);
    }
  };

  const startRecording = (stream: MediaStream) => {
    recordedBlobsRef.current = [];
    let options = getMediaRecorderOptions();

    try {
      const mediaRecorder = new MediaRecorder(stream, options);
      mediaRecorder.ondataavailable = handleRecordedData;
      mediaRecorder.onstop = () => {
        setVideoRecordingBlob(getRecordingBlob());
      };
      mediaRecorder.onstart = () => {};
      mediaRecorder.ondataavailable = handleRecordedData;
      mediaRecorder.start(50); // collect 50ms of data
      setMediaRecorder(mediaRecorder);
    } catch (e) {
      setVideoStreamError(e);
      return;
    }
  };

  const stopRecording = () => {
    if (mediaRecorder && mediaRecorder.state === 'recording') {
      mediaRecorder.stop();
    }
  };

  const handleSuccess = (
    stream: MediaStream,
    shouldSaveRecording?: boolean
  ): Promise<MediaDeviceInfo[]> => {
    setVideoStreamError(null);
    setVideoStream(stream);
    if (shouldSaveRecording) {
      startRecording(stream);
    }
    setVideoStreamLoading(false);
    return navigator.mediaDevices.enumerateDevices();
  };

  const handleError = (error: Error) => {
    setVideoStreamError(error);
    setVideoStreamLoading(false);
    if (error.name === 'PermissionDeniedError') {
      // Todo handle this error
      setVideoStreamError(new CameraPermissionsDenied());
      return;
    }
    if (error.name === 'NotFoundError') {
      // Todo handle this error
      setVideoStreamError(new NoWebcam());
      return;
    }
    setVideoStreamError(error);
  };

  const saveMediaDevices = (devices: MediaDeviceInfo[]) => {
    if (availableMediaDevices.current.length === 0) {
      const availableDevices = getAvailableDevices(devices);
      availableMediaDevices.current.push(...availableDevices);
    }
  };

  const getAvailableDevices = (
    devices: MediaDeviceInfo[]
  ): MediaDeviceInfo[] => {
    const videoDevices = filterUniqueVideoDevices(devices);
    if (videoDevices.length === 0) {
      setVideoStreamError(new NoWebcam());
    }
    return videoDevices;
  };

  // Check if current browser is support with Media Streams/Recording API
  const checkCompatibility = (): Boolean => {
    const userMediasAPI = hasUserMediasAPI();
    const mediaRecorderAPI = hasMediaRecorderAPI();
    const webRtcAPI = hasWebRtcApi();

    if (isiOS()) {
      return false;
    }
    if (userMediasAPI && (mediaRecorderAPI || webRtcAPI)) {
      return true;
    } else {
      setVideoStreamError(new DeviceNotSupported());
      return false;
    }
  };

  const startVideoStream = (
    shouldFaceUser = true,
    shouldSaveRecording = false,
    device: MediaDeviceInfo | null = null
  ) => {
    if (checkCompatibility()) {
      // Stop previous video streams
      stopVideoStream();
      setVideoStream(null);
      setMediaRecorder(null);
      setVideoStreamLoading(true);
      const constrains = getMediaConstrains(shouldFaceUser, device);
      currentUserFacing.current = shouldFaceUser
        ? cameraFacing.user
        : cameraFacing.environment;
      navigator.mediaDevices
        .getUserMedia(constrains)
        .then((mediaDeviceInfo) =>
          handleSuccess(mediaDeviceInfo, shouldSaveRecording)
        )
        .then(saveMediaDevices)
        // TODO: check for webcam if exists
        .catch(handleError);
    } else {
      handleError(new DeviceNotSupported());
    }
  };

  const stopVideoStream = () => {
    if (videoStream) {
      videoStream.getTracks().forEach((videoTrack) => {
        videoTrack.stop();
      });
    }
  };

  const switchCamera = (shouldSaveRecording = false) => {
    const faceUserAfterSwitch = currentUserFacing.current !== cameraFacing.user;
    startVideoStream(faceUserAfterSwitch, shouldSaveRecording);
  };

  const getSnapShot = (video: HTMLVideoElement): Promise<Blob> => {
    return new Promise<Blob>((resolve, reject) => {
      const canvas = photoSnapShotCanvasRef.current;
      if (canvas) {
        // Draw image to Canvas
        const { videoWidth, videoHeight } = video;
        canvas.width = videoWidth;
        canvas.height = videoHeight;
        canvas
          ?.getContext('2d')
          ?.drawImage(video, 0, 0, videoWidth, videoHeight);
        // Make image blob from canvas
        canvas?.toBlob((blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(CanNotMakeSnapshotBlobError);
          }
        }, 'image/jpeg');
      } else {
        reject(CanNotMakeSnapshotBlobError);
      }
    });
  };

  const hasMultipleCameras = () => {
    return availableMediaDevices.current.length > 1;
  };

  const isCameraFacingUser = (): boolean => {
    // If not multiple camera are present we assume that
    // the single camera faces the user
    // if (!hasMultipleCameras()) {
    //   alert('Should be true')
    //   return false;
    // }
    return currentUserFacing.current === cameraFacing.user;
  };

  return (
    <Provider
      value={{
        videoStream,
        videoStreamLoading,
        videoStreamError,
        startVideoStream,
        stopVideoStream,
        getRecordingBlob,
        getSnapShot,
        checkCompatibility,
        getAvailableDevices,
        switchCamera,
        hasMultipleCameras,
        isCameraFacingUser,
        startRecording,
        stopRecording,
      }}
    >
      {/* Hidden canvas used for making video snapshots*/}
      <canvas ref={photoSnapShotCanvasRef} style={photoSnapShotCanvas} />
      {children}
    </Provider>
  );
};

export { videoStreamStore, VideoStreamProvider };
