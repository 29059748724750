import React, { useContext, useRef } from 'react';
import { videoStreamStore } from '../../../stores/videoContext';
import { useVerification } from '../../../stores/verificationContext';
import SwitchCameraIcon from '@material-ui/icons/FlipCameraIosRounded';
import { videoWrapperStyles } from '../../cameraPreview/styles';
import clsx from 'clsx';
import VerificationContainer from '../container/index.js';

import { useTranslation } from 'react-i18next';

type DocumentPhotoVerificationProps = {
  next: Function;
};

export default function DocumentPhotoVerification({
  next,
}: DocumentPhotoVerificationProps) {
  const { t } = useTranslation(['app']);

  const {
    videoStream,
    videoStreamError,
    getSnapShot,
    switchCamera,
    hasMultipleCameras,
    isCameraFacingUser,
  } = useContext(videoStreamStore);
  const classes = videoWrapperStyles();
  const mediaStreamRef = useRef(null);
  let videoElement = useRef<HTMLVideoElement | null>(null);
  const { setDocumentPhotoBlob } = useVerification();

  const addMediaStream = (video: HTMLVideoElement) => {
    if (videoStreamError) {
      // TODO: Handle specific errors
      //  like allow camera permissions
      // WebRTC not supported etc...
    }
    // Set the stream source on the video element
    if (videoStream && video) {
      videoElement.current = video;
      video.srcObject = videoStream;
    }
  };

  const validate = () => {
    // TODO: Validate
    if (videoElement.current) {
      getSnapShot(videoElement.current).then((blob) => {
        setDocumentPhotoBlob(blob);
        next();
      });
    }
  };

  return (
    <VerificationContainer
      next={validate}
      headerText={t(
        'app:takeAFrontSidePictureOfIdDocument',
        'Take a front side picture of ID Document'
      )}
      captionText={t(
        'app:pleaseMakeSureYourIdentityDocumentIsVisibleAndClear',
        'Please make sure your identity document is visible and clear.'
      )}
      buttonText={t('app:buttonTakeAPicture', 'Take a picture')}
      buttonDisabled={!videoStream}
      stretch
    >
      <div>{t('app:buttonTakeAPicture', 'Take a picture')}</div>
      <div className={classes.videoWrapper}>
        <span className={classes.documentOverlay}></span>
        <video
          width={'100%'}
          height={'100%'}
          playsInline
          autoPlay
          className={clsx(classes.video, {
            [classes.mirrorVideo]: isCameraFacingUser(),
          })}
          ref={mediaStreamRef ? addMediaStream : null}
        />
        {hasMultipleCameras() && (
          <SwitchCameraIcon
            className={classes.switchCameraCenter}
            onClick={() => switchCamera()}
          />
        )}
      </div>
    </VerificationContainer>
  );
}
