import React from 'react';
import { VerificationToken } from './types';

type VerificationContextInterface = {
  verification: VerificationToken | null;
};

const defaultAppContext: VerificationContextInterface = {
  verification: null,
};

export const VerificationTokenContext = React.createContext(defaultAppContext);
export const VerificationTokenProvider = VerificationTokenContext.Provider;
export const VerificationTokenConsumer = VerificationTokenContext.Consumer;
