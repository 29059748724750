import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

import {
  CountryTypeWithVerificationIds,
  IDType,
} from '../../components/verification/countrySelect/types';
import { VerificationToken } from '../../screens/main/types';
import { getMediaRecorderOptionsExtension } from '../videoContext/utils';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import * as Sentry from '@sentry/react';

type InitialStateInterface = {
  verificationToken: VerificationToken | null;
  firstName: string | null;
  setFirstName: Dispatch<SetStateAction<string | null>>;
  middleName: string | null;
  setMiddleName: Dispatch<SetStateAction<string | null>>;
  lastName: string | null;
  setLastName: Dispatch<SetStateAction<string | null>>;
  email: string | null;
  setEmail: Dispatch<SetStateAction<string | null>>;
  birthday: MaterialUiPickersDate | null;
  setBirthday: Dispatch<SetStateAction<MaterialUiPickersDate | null>>;
  gender: string | null;
  setGender: Dispatch<SetStateAction<string | null>>;
  phoneNumber: string | null;
  setPhoneNumber: Dispatch<SetStateAction<string | null>>;
  phoneNumberVerified: Boolean | null;
  setPhoneNumberVerified: Dispatch<SetStateAction<Boolean | null>>;
  phoneNumberCountry: object | null;
  setPhoneNumberCountry: Dispatch<SetStateAction<object | null>>;
  selectedResidenceCountry: CountryTypeWithVerificationIds | null;
  setSelectedResidenceCountry: Dispatch<
    SetStateAction<CountryTypeWithVerificationIds | null>
  >;
  selectedCountry: CountryTypeWithVerificationIds | null;
  setSelectedCountry: Dispatch<
    SetStateAction<CountryTypeWithVerificationIds | null>
  >;
  selectedIdType: IDType | null;
  setSelectedIdType: Dispatch<SetStateAction<IDType | null>>;
  documentPhotoBlob: Blob;
  documentBackPhotoBlob: Blob;
  selfiePhotoBlob: Blob;
  videoRecordingBlob: Blob;
  setDocumentPhotoBlob: Dispatch<SetStateAction<Blob>>;
  setDocumentBackPhotoBlob: Dispatch<SetStateAction<Blob>>;
  setSelfiePhotoBlob: Dispatch<SetStateAction<Blob>>;
  setVideoRecordingBlob: Dispatch<SetStateAction<Blob>>;
  submitVerification(
    documentBlob: Blob,
    documentBackBlob: Blob,
    selfieBlob: Blob,
    recordingBlob: Blob,
    language?: string
  ): Promise<Response>;
  createPhoneVerification(language?: string, phone?: string): Promise<Response>;
};

const initialState: InitialStateInterface = {
  verificationToken: null,
  firstName: null,
  setFirstName: () => {},
  middleName: null,
  setMiddleName: () => {},
  lastName: null,
  setLastName: () => {},
  email: null,
  setEmail: () => {},
  birthday: null,
  setBirthday: () => {},
  gender: null,
  setGender: () => {},
  phoneNumber: null,
  setPhoneNumber: () => {},
  phoneNumberVerified: false,
  setPhoneNumberVerified: () => {},
  phoneNumberCountry: null,
  setPhoneNumberCountry: () => {},
  selectedResidenceCountry: null,
  setSelectedResidenceCountry: () => {},
  selectedCountry: null,
  setSelectedCountry: () => {},
  selectedIdType: null,
  setSelectedIdType: () => {},
  documentPhotoBlob: new Blob(),
  documentBackPhotoBlob: new Blob(),
  selfiePhotoBlob: new Blob(),
  videoRecordingBlob: new Blob(),
  setDocumentPhotoBlob: () => {},
  setDocumentBackPhotoBlob: () => {},
  setSelfiePhotoBlob: () => {},
  setVideoRecordingBlob: () => {},
  submitVerification: (
    documentBlob: Blob,
    documentBackBlob: Blob,
    selfieBlob: Blob,
    recordingBlob: Blob,
    language?: string
  ) => {
    return new Promise<Response>(() => {});
  },
  createPhoneVerification: (language?: string, phone?: string) => {
    return new Promise<Response>(() => {});
  },
};

/**
 * Provides the state of the modal
 * By default it will be open
 */
const useVerificationHook = (
  verificationToken: VerificationToken
): InitialStateInterface => {
  const [firstName, setFirstName] = React.useState<string | null>(
    verificationToken.first_name
  );
  const [middleName, setMiddleName] = React.useState<string | null>(
    verificationToken.middle_name
  );
  const [lastName, setLastName] = React.useState<string | null>(
    verificationToken.last_name
  );
  const [email, setEmail] = React.useState<string | null>(
    verificationToken.email
  );
  const [birthday, setBirthday] = React.useState<MaterialUiPickersDate | null>(
    verificationToken.date_of_birth
      ? moment(verificationToken.date_of_birth)
      : null
  );
  const [gender, setGender] = React.useState<string | null>(
    verificationToken.gender
  );
  const [phoneNumber, setPhoneNumber] = React.useState<string | null>(
    verificationToken.phone_number
  );
  const [phoneNumberCountry, setPhoneNumberCountry] = React.useState<
    object | null
  >(verificationToken.phone_number_country);
  const [
    phoneNumberVerified,
    setPhoneNumberVerified,
  ] = React.useState<Boolean | null>(verificationToken.phone_verified);
  const [
    selectedResidenceCountry,
    setSelectedResidenceCountry,
  ] = React.useState<CountryTypeWithVerificationIds | null>(
    verificationToken.country
  );
  const [
    selectedCountry,
    setSelectedCountry,
  ] = React.useState<CountryTypeWithVerificationIds | null>(
    verificationToken.nationality
  );
  const [selectedIdType, setSelectedIdType] = React.useState<IDType | null>(
    verificationToken.document_type
  );
  const [documentPhotoBlob, setDocumentPhotoBlob] = React.useState<Blob>(
    new Blob()
  );
  const [documentBackPhotoBlob, setDocumentBackPhotoBlob] = React.useState<
    Blob
  >(new Blob());
  const [selfiePhotoBlob, setSelfiePhotoBlob] = React.useState<Blob>(
    new Blob()
  );
  const [videoRecordingBlob, setVideoRecordingBlob] = React.useState<Blob>(
    new Blob()
  );

  const submitVerification = (
    documentBlob: Blob,
    documentBackBlob: Blob,
    selfieBlob: Blob,
    recordingBlob: Blob,
    language?: string
  ) => {
    return new Promise<Response>((resolve, reject) => {
      const formData = new FormData();

      if (!language) {
        language = 'en';
      }

      // Append selected language
      formData.append('language', language);

      formData.append('documentPhoto', documentBlob, 'document.jpg');
      if (selectedIdType?.has_back) {
        formData.append('documentBack', documentBackBlob, 'documentBack.jpg');
      }
      formData.append('selfiePhoto', selfieBlob, 'selfie.jpg');
      formData.append(
        'videoFiles[]',
        recordingBlob,
        `recording.` + getMediaRecorderOptionsExtension()
      );

      fetch(
        `${process.env.REACT_APP_URI}/api/submit/${verificationToken?.hash}`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Accept: 'application/json',
          },
        }
      )
        .then((response) => resolve(response))
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };

  const createPhoneVerification = (language?: string, phone?: string) => {
    return new Promise<Response>((resolve, reject) => {
      const formData = new FormData();

      if (!language) {
        language = 'en';
      }

      if (!phone) {
        /*
        return [
          'errors': {
            'type': 'PHONE_ERROR'
          }
        ];
        */
        phone = '';
      }

      // Append selected language
      formData.append('language', language);
      formData.append('phone', phone);

      fetch(
        `${process.env.REACT_APP_URI}/api/phone-verification/${verificationToken?.hash}`,
        {
          method: 'POST',
          body: formData,
          headers: {
            Accept: 'application/json',
          },
        }
      )
        .then((response) => resolve(response))
        .catch((error) => {
          Sentry.captureException(error);
          reject(error);
        });
    });
  };

  return {
    verificationToken,
    firstName,
    setFirstName,
    middleName,
    setMiddleName,
    lastName,
    setLastName,
    email,
    setEmail,
    birthday,
    setBirthday,
    gender,
    setGender,
    phoneNumber,
    setPhoneNumber,
    phoneNumberCountry,
    setPhoneNumberCountry,
    phoneNumberVerified,
    setPhoneNumberVerified,
    selectedCountry,
    selectedResidenceCountry,
    setSelectedResidenceCountry,
    setSelectedCountry,
    selectedIdType,
    setSelectedIdType,
    documentPhotoBlob,
    setDocumentPhotoBlob,
    documentBackPhotoBlob,
    setDocumentBackPhotoBlob,
    selfiePhotoBlob,
    setSelfiePhotoBlob,
    videoRecordingBlob,
    setVideoRecordingBlob,
    submitVerification,
    createPhoneVerification,
  };
};

/**
 * Defines a context where the completion state is stored and shared
 *
 * - This serves as a cache.
 * - Rather than each instance of the hook fetch the current state, the hook simply calls useContext to get the data from the top level provider
 */
const verificationContext = createContext(initialState);

/**
 * Provides a top level wrapper with the context
 *
 * - This is the main provider
 * - It makes the object available to any child component that calls the hook.
 */
const VerificationProvider = (props: any) => {
  const { children } = props;
  const { verification } = props;

  const verificationData = useVerificationHook(verification);

  return (
    <verificationContext.Provider value={{ ...verificationData }}>
      {children}
    </verificationContext.Provider>
  );
};

/**
 * Defines the main hook
 *
 * - Returns the  context / object
 */
const useVerification = () => {
  return useContext(verificationContext);
};

export { useVerification, VerificationProvider };
