import React, { useContext, useRef, useEffect } from 'react';
import { videoStreamStore } from '../../../stores/videoContext';
import SwitchCameraIcon from '@material-ui/icons/FlipCameraIosRounded';
import { videoWrapperStyles } from '../../cameraPreview/styles';
import clsx from 'clsx';
import VerificationContainer from '../container/index.js';

import { useTranslation } from 'react-i18next';

type VideoRecordingProps = {
  next: Function;
};

export default function VideoRecording({ next }: VideoRecordingProps) {
  const { t } = useTranslation(['app']);

  const {
    videoStream,
    startVideoStream,
    stopRecording,
    switchCamera,
    hasMultipleCameras,
    isCameraFacingUser,
  } = useContext(videoStreamStore);

  useEffect(() => {
    startVideoStream(true, true);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = videoWrapperStyles();

  const mediaStreamRef = useRef(null);

  const addMediaStream = (video: HTMLVideoElement) => {
    // Video stream should be already available from the previous step no need
    // handle errors here
    if (videoStream && video) {
      video.srcObject = videoStream;
    }
  };

  const validate = () => {
    stopRecording();
    next();
  };

  // Used to determine CSS classes
  const cameraFacingUser = isCameraFacingUser();

  return (
    <VerificationContainer
      next={validate}
      headerText={t('app:videoRecordingTitle', 'Video recording')}
      captionText={t(
        'app:videoRecordingText',
        'Look in to the camera, hold your document next to your face, and smile.'
      )}
      buttonText={t('app:buttonContinue', 'Continue')}
      buttonDisabled={!videoStream}
      stretch
    >
      <div className={`${classes.videoWrapper}`}>
        <div className={classes.left}>
          <span className={`${classes.documentOverlay} video`} />
        </div>
        <div className={classes.right}>
          <span className={`${classes.faceOverlay} video`} />
        </div>
        <video
          width={'100%'}
          height={'100%'}
          playsInline
          autoPlay
          className={clsx(classes.video, {
            [classes.mirrorVideo]: cameraFacingUser,
          })}
          ref={mediaStreamRef ? addMediaStream : null}
        />

        {hasMultipleCameras() && (
          <SwitchCameraIcon
            className={classes.switchCameraCenter}
            onClick={() => switchCamera(true)}
          />
        )}
      </div>
    </VerificationContainer>
  );
}
