import React from 'react';
import {
  Box,
  FormControl,
  FormLabel,
  Grid,
  Typography,
} from '@material-ui/core';
import CountrySelector from '../../countrySelector';
import { gql } from 'apollo-boost';
import { useMutation, useQuery } from '@apollo/react-hooks';
import { ApiCountriesResponse, CountryTypeWithVerificationIds } from './types';
import { useVerification } from '../../../stores/verificationContext';
import VerificationContainer from '../container/index.js';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import i18n, { documentTranslation } from '../../../i18next';

import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';
import { makeStyles } from '@material-ui/core/styles';

type CountryIDSelectorProps = {
  next: Function;
};

const GET_COUNTRIES = gql`
  {
    countries {
      id
      name
      code
      idTypes {
        id
        name
        has_back
      }
    }
  }
`;

const SUBMIT_VERIFICATION = gql`
  mutation(
    $token: String!
    $countryId: Int!
    $idType: Int!
    $firstName: String!
    $middleName: String
    $lastName: String!
    $email: String!
    $birthday: String
    $gender: String
    $phoneNumber: String
    $residenceCountryId: Int!
    $language: String
  ) {
    submitVerification(
      hash: $token
      first_name: $firstName
      middle_name: $middleName
      last_name: $lastName
      email: $email
      birthday: $birthday
      gender: $gender
      phone_number: $phoneNumber
      residence_country_id: $residenceCountryId
      country_id: $countryId
      id_type_id: $idType
      language: $language
    ) {
      hash
    }
  }
`;

export default function CountryIDSelector({ next }: CountryIDSelectorProps) {
  const { data } = useQuery<ApiCountriesResponse>(GET_COUNTRIES);
  const [submitVerification, { loading }] = useMutation(SUBMIT_VERIFICATION);

  const { t }: { t: any } = useTranslation(['app']);

  const {
    verificationToken,
    firstName,
    middleName,
    lastName,
    email,
    birthday,
    gender,
    phoneNumber,
    selectedResidenceCountry,
    setSelectedResidenceCountry,
    selectedCountry,
    setSelectedCountry,
    selectedIdType,
    setSelectedIdType,
  } = useVerification();

  const useStyles = makeStyles({
    placeHolderImage: {
      position: 'absolute',
      top: '50px',
      left: '-30px',
      height: '20px',
      width: '20px',
      objectFit: 'cover',
      transition: 'opacity .5s linear',
      transform: 'translate(0)',
      opacity: '0',
    },
    positionRelative: {
      position: 'relative',
    },
  });

  const classes = useStyles();

  let countries: CountryTypeWithVerificationIds[] = [];

  if (data && data.countries) {
    countries = data.countries.filter((c) => c.idTypes.length > 0);
  }

  const getCountryIdTypesOptions = () => {
    if (selectedCountry) {
      const currentSelected = countries.find(
        (country) => country.id === selectedCountry.id
      );
      if (currentSelected && currentSelected.idTypes.length > 0) {
        return currentSelected.idTypes.map((idType) => {
          return (
            <MenuItem key={idType.id} value={idType.id}>
              {t('app:' + documentTranslation(idType.name), idType.name)}
            </MenuItem>
          );
        });
      } else {
        return (
          <Typography>
            {t(
              'app:countrySelectNotSupported',
              'Verification not supported for your country'
            )}
          </Typography>
        );
      }
    }

    return null;
  };

  const validate = () => {
    submitVerification({
      variables: {
        token: verificationToken?.hash,
        firstName: firstName,
        middleName: middleName,
        lastName: lastName,
        email: email,
        birthday: birthday?.format('YYYY-MM-DD'),
        gender: gender,
        phoneNumber: phoneNumber,
        residenceCountryId: selectedResidenceCountry?.id,
        countryId: selectedCountry?.id,
        idType: selectedIdType?.id,
        language: i18n.language,
      },
    })
      .then((r) => {
        next();
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
      });
  };

  let manualInput = false;
  const setManualInput = (value) => {
    manualInput = value;
  };

  const onIdTypeChange = (e) => {
    const formSelectedIdType = (e.target as HTMLInputElement).value;
    const selectedIdType = selectedCountry?.idTypes.find(
      (idType) => idType.id === formSelectedIdType
    );

    setSelectedIdType(selectedIdType || null);
    setManualInput(true);
  };

  const onCountryChange = (e: CountryTypeWithVerificationIds) => {
    setSelectedCountry(e);
    // Clear the selected ID Type when country changes
    setSelectedIdType(null);
    setManualInput(true);
  };

  const onResidenceCountryChange = (e: CountryTypeWithVerificationIds) => {
    setSelectedResidenceCountry(e);
    setManualInput(true);
  };

  const inputValidated = () => {
    return selectedResidenceCountry && selectedCountry && selectedIdType;
  };

  if (inputValidated() && verificationToken?.has_parent && !manualInput) {
    // next();
  }

  return (
    <VerificationContainer
      next={validate}
      headerText={t('app:countrySelectTitle', 'Select your country and ID')}
      captionText={''}
      buttonText={t('app:buttonContinue', 'Continue')}
      buttonDisabled={!inputValidated()}
      loading={loading}
    >
      <Grid
        container
        spacing={2}
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item style={{ width: '100%' }}>
          <Box mb={2} className={classes.positionRelative}>
            <FormLabel component="legend">
              {t(
                'app:countrySelectFormResidenceCountry',
                'Country of residence'
              )}
            </FormLabel>
          </Box>
          <CountrySelector
            countries={countries}
            selectedCountry={selectedResidenceCountry}
            onSelect={onResidenceCountryChange}
            inputLabel={t(
              'app:countrySelectFormResidenceCountry',
              'Country of residence'
            )}
          />
        </Grid>
        <Grid item style={{ width: '100%' }}>
          <Box mb={2} className={classes.positionRelative}>
            <FormLabel component="legend">
              {t('app:countrySelectFormCountryLabel', 'Nationality')}
            </FormLabel>
          </Box>
          <CountrySelector
            countries={countries}
            selectedCountry={selectedCountry}
            onSelect={onCountryChange}
            inputLabel={t('app:countrySelectFormCountryLabel', 'Nationality')}
          />
        </Grid>
        <Grid
          item
          style={{ visibility: selectedCountry ? 'visible' : 'hidden' }}
        >
          <FormLabel component="legend" style={{ marginBottom: 10 }}>
            {t(
              'app:countrySelectFormSelectedIdTypeLabel',
              'Which identity document would you like to use?'
            )}
          </FormLabel>
          <FormControl variant="outlined" className={'asd'} fullWidth={true}>
            <InputLabel htmlFor="document-type">
              {t('app:countrySelectFormDocumentLabel', 'Document')}
            </InputLabel>
            <Select
              id="document-type"
              value={selectedIdType?.id || null}
              onChange={onIdTypeChange}
              label={t('app:countrySelectFormDocumentLabel', 'Document')}
            >
              <MenuItem value="">
                <em>{t('app:labelNone', 'None')}</em>
              </MenuItem>
              {getCountryIdTypesOptions()}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </VerificationContainer>
  );
}
