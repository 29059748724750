import React, { useContext, useEffect } from 'react';
import { videoStreamStore } from '../../../stores/videoContext';
import VerificationContainer from '../container/index.js';
import showIdSvg from '../../../assets/images/showId.svg';
import AllowCameraUseError from '../../cameraPermissionsError';
import { videoWrapperStyles } from '../../cameraPreview/styles';

import { useTranslation } from 'react-i18next';
import {
  Grid,
  List,
  ListItemText,
  ListItem,
  ListItemIcon,
} from '@material-ui/core';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

type AllowCameraPermissionsProps = {
  next: Function;
};

export default function BeforeDocumentPhoto({
  next,
}: AllowCameraPermissionsProps) {
  const { t } = useTranslation(['app']);

  const { videoStream, startVideoStream, videoStreamError } = useContext(
    videoStreamStore
  );

  const classes = videoWrapperStyles();

  useEffect(() => {
    startVideoStream(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validate = () => {
    // TODO: Validate
    next();
  };

  return (
    <VerificationContainer
      next={validate}
      headerText={t(
        'app:beforeDocumentPhotoTitle',
        'Photo side of your identity document'
      )}
      buttonText={t('app:okGotItButton', 'Ok, got it')}
      buttonDisabled={!videoStream}
      stretch
    >
      {videoStreamError && <AllowCameraUseError />}
      <img src={showIdSvg} className={classes.guideImage} alt={''} />
      <Grid alignItems={'center'} alignContent={'center'}>
        <List dense>
          <ListItem disableGutters>
            <ListItemIcon>
              <ChevronRightIcon color={'primary'} />
            </ListItemIcon>
            <ListItemText
              primary={t(
                'app:makeSureYouAreInAWellLitRoom',
                'Make sure you are in a well lit room'
              )}
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <ChevronRightIcon color={'primary'} />
            </ListItemIcon>
            <ListItemText
              primary={t(
                'app:avoidDirectSunlightToRemoveAnyGlare',
                'Avoid direct sunlight to remove any glare'
              )}
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <ChevronRightIcon color={'primary'} />
            </ListItemIcon>
            <ListItemText
              primary={t(
                'app:makeSureIdDocumentIsInFocus',
                'Make sure the ID document is in focus'
              )}
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <ChevronRightIcon color={'primary'} />
            </ListItemIcon>
            <ListItemText
              primary={t(
                'app:makeSureAllCornersOfTheIdDocumentAreVisible',
                'Make sure all corners of the ID Documents are visible'
              )}
            />
          </ListItem>
          <ListItem disableGutters>
            <ListItemIcon>
              <ChevronRightIcon color={'primary'} />
            </ListItemIcon>
            <ListItemText
              primary={t(
                'app:doNotCoverAnyImportantData',
                'Do not cover any important data'
              )}
            />
          </ListItem>
        </List>
      </Grid>
    </VerificationContainer>
  );
}
