import React, { useEffect, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';
import VerificationContainer from '../container/index.js';
import { Element, Link } from 'react-scroll';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useVerification } from '../../../stores/verificationContext';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';
import { getTranslationHtml } from '../../../i18next';
import renderHTML from 'react-render-html';

const useStyles = makeStyles((theme) => ({
  box: {
    height: '600px',
    maxHeight: '600px',
    minHeight: '200px',
    '@media (max-height:810px)': {
      height: 'calc(100vh - 250px)',
    },
  },
}));

type GuidelinesProps = {
  next: Function;
};

export default function Guidelines({ next }: GuidelinesProps) {
  const { t, i18n } = useTranslation(['app']);

  // Fetch page HTML content form server
  const [htmlContent, setHtmlContent] = useState('loading');
  useEffect(() => {
    getTranslationHtml(i18n.language, 'guidelines.html', setHtmlContent);
  }, [i18n.language, setHtmlContent]);

  const classes = useStyles();
  const validate = () => {
    next();
  };

  const { verificationToken } = useVerification();

  const [endReached, setEndReached] = useState<boolean>(false);
  const [hideScrollToEnd, setHideScrollToEnd] = useState<boolean>(false);

  const onScroll = (e) => {
    let current = Math.round(e.target.scrollHeight - e.target.scrollTop);
    let original = Math.round(e.target.clientHeight);
    if (current <= original) {
      setEndReached(true);
      setHideScrollToEnd(true);
    } else {
      setHideScrollToEnd(false);
    }
  };

  const inputValidated = () => {
    return endReached;
  };

  if (verificationToken?.terms_accepted) {
    next();
  }

  /* eslint-disable */
  return (
    <VerificationContainer
      next={validate}
      headerText={t('app:startTitle', 'EVALID LTD. GUIDELINES')}
      captionText={''}
      buttonText={t('app:buttonOk', 'OK')}
      buttonDisabled={!inputValidated()}
    >
      <Grid
        container
        spacing={2}
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item style={{ width: '100%' }}>
          <Box
            className={classes.box}
            overflow={'auto'}
            id={'terms'}
            onScroll={onScroll}
            position={'relative'}
            style={{ willChange: 'transform' }}
          >

            {renderHTML(htmlContent)}

            <Element name="listEnd" className="element" />
            {!hideScrollToEnd && (
              <Box style={{ position: 'sticky', bottom: 45 }}>
                <Link
                  to="listEnd"
                  containerId="terms"
                  smooth={true}
                  duration={500}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    style={{ position: 'absolute', right: 20, minWidth: 14 }}
                  >
                    <ExpandMoreIcon />
                  </Button>
                </Link>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </VerificationContainer>
  );
}
