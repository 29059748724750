import React, { useContext } from 'react';
import { videoStreamStore } from '../../../stores/videoContext';
import VerificationContainer from '../container/index.js';
import documentFlipSVG from '../../../assets/images/documentFlip.svg';
import AllowCameraUseError from '../../cameraPermissionsError';
import { videoWrapperStyles } from '../../cameraPreview/styles';

import { useTranslation } from 'react-i18next';

type AllowCameraPermissionsProps = {
  next: Function;
};

export default function BeforeDocumentBackPhoto({
  next,
}: AllowCameraPermissionsProps) {
  const { t } = useTranslation(['app']);

  const { videoStream, videoStreamError } = useContext(videoStreamStore);
  const classes = videoWrapperStyles();

  const validate = () => {
    // TODO: Validate
    next();
  };

  return (
    <VerificationContainer
      next={validate}
      headerText={t(
        'app:beforeDocumentBackPhotoTitle',
        'Turn your document around'
      )}
      captionText={t(
        'app:beforeDocumentBackPhotoText',
        'Turn your document around and take a photo of the other side. (If your document does not have another side, please take another photo of the front)'
      )}
      buttonText={t('app:okGotItButton', 'Ok, got it')}
      buttonDisabled={!videoStream}
      stretch
    >
      {videoStreamError && <AllowCameraUseError />}
      <img
        src={documentFlipSVG}
        className={classes.placeHolderImage}
        alt={''}
      />
    </VerificationContainer>
  );
}
