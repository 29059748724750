import React from 'react';
import { Typography } from '@material-ui/core';

function PendingScreen() {
  return (
    <Typography variant="h1" color={'primary'} align={'center'}>
      Verification Pending
    </Typography>
  );
}

export default PendingScreen;
