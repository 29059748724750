import React, { useContext, useEffect } from 'react';
import Guidelines from '../../components/verification/guidelines/index';
import ReadGuidelines from '../../components/verification/readGuidelines/index.js';
import AcceptTermsAndConditions from '../../components/verification/acceptTermsAndConditions/index.js';
import ReadTermsAndConditions from '../../components/verification/readTermsAndConditions/index.js';
import GeneralInfo from '../../components/verification/generalInfo/index.js';
import CountryIDSelector from '../../components/verification/countrySelect/index.js';
import DocumentPhotoVerification from '../../components/verification/documentPhoto/index.js';
import SelfieVerification from '../../components/verification/selfie/index.js';
import VerificationStart from '../../components/verification/start/index.js';
import ThankYou from '../thank-you';
import CompatibilityTest from '../../components/verification/compatibility/index.js';
import DocumentBackPhotoVerification from '../../components/verification/documentBackPhoto/index.js';
import BeforeDocumentPhoto from '../../components/verification/beforeDocumentPhoto/index.js';
import BeforeSelfiePhoto from '../../components/verification/beforeSelfiePhoto/index.js';
import BeforeDocumentBackPhoto from '../../components/verification/beforeDocumentBackPhoto/index.js';
import ReviewDocumentPhoto from '../../components/verification/reviewDocumentPhoto/index';
import ReviewDocumentBackPhoto from '../../components/verification/reviewDocumentBackPhoto/index';
import ReviewSelfieVerification from '../../components/verification/reviewSelfieVerification/index';
import BeforeVideoRecording from '../../components/verification/beforeVideoRecording/index';
import VideoRecording from '../../components/verification/videoRecording/index';
import ReviewVideoRecording from '../../components/verification/reviewVideoRecording/index';
import { useVerification } from '../../stores/verificationContext';
import { videoStreamStore } from '../../stores/videoContext';
import { NoWebcam } from '../../stores/videoContext/errors';
import QRCodeStep from '../../components/verification/qrCodeStep';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';

const DOCUMENT_PHOTO_STEP = 5;
const QR_CODE_STEP = 99;
const READ_TOS_STEP = 91;
const READ_GUIDELINES_STEP = 92;

const SAVE_STEP = gql`
  mutation submitVerificationStep($token: String!, $step_slug: String!) {
    submitVerificationStep(hash: $token, step_slug: $step_slug) {
      hash
      step_slug
    }
  }
`;

// Every step added to getStepContent must also be added here
function getStepSlug(step: number): string {
  switch (step) {
    case -2:
      return 'CompatibilityTest';
    case -1:
      return 'VerificationStart';
    case 0:
      return 'Guidelines';
    case 1:
      return 'AcceptTermsAndConditions';
    case 2:
      return 'GeneralInfo';
    case 3:
      return 'CountryIDSelector';
    case 4:
      return 'BeforeDocumentPhoto';
    case DOCUMENT_PHOTO_STEP:
      return 'DocumentPhotoVerification';
    case 6:
      return 'ReviewDocumentPhoto';
    case 7:
      return 'BeforeDocumentBackPhoto';
    case 8:
      return 'DocumentBackPhotoVerification';
    case 9:
      return 'ReviewDocumentBackPhoto';
    case 10:
      return 'BeforeSelfiePhoto';
    case 11:
      return 'SelfieVerification';
    case 12:
      return 'ReviewSelfieVerification';
    case 13:
      return 'BeforeVideoRecording';
    case 14:
      return 'VideoRecording';
    case 15:
      return 'ReviewVideoRecording';
    case 16:
      return 'ThankYou';
    case QR_CODE_STEP:
      return 'QRCodeStep';
    case READ_GUIDELINES_STEP:
      return 'ReadTermsAndConditions';
    case READ_TOS_STEP:
      return 'ReadTermsAndConditions';
    default:
      return 'InvalidStep';
  }
}

// Every step added here, must also be added to getStepSlug
function getStepContent(
  step: number,
  nextCallback: Function,
  prevCallback: Function,
  backFromQRCode: Function,
  photoRetries: Number
) {
  Sentry.addBreadcrumb({
    category: 'navitagion',
    message: 'Opening page ' + step,
    level: Sentry.Severity.Info,
  });
  switch (step) {
    case 0:
      return <Guidelines next={nextCallback} />;
    case 1:
      return <AcceptTermsAndConditions next={nextCallback} />;
    case 2:
      return <GeneralInfo next={nextCallback} />;
    case 3:
      return <CountryIDSelector next={nextCallback} />;
    case 4:
      return <BeforeDocumentPhoto next={nextCallback} />;
    case DOCUMENT_PHOTO_STEP:
      return <DocumentPhotoVerification next={nextCallback} />;
    case 6:
      return (
        <ReviewDocumentPhoto
          next={nextCallback}
          prev={prevCallback}
          retries={photoRetries}
          maxRetries={3}
        />
      );
    case 7:
      return <BeforeDocumentBackPhoto next={nextCallback} />;
    case 8:
      return <DocumentBackPhotoVerification next={nextCallback} />;
    case 9:
      return (
        <ReviewDocumentBackPhoto next={nextCallback} prev={prevCallback} />
      );
    case 10:
      return <BeforeSelfiePhoto next={nextCallback} />;
    case 11:
      return <SelfieVerification next={nextCallback} />;
    case 12:
      return (
        <ReviewSelfieVerification
          next={nextCallback}
          prev={prevCallback}
          retries={photoRetries}
          maxRetries={3}
        />
      );
    case 13:
      return <BeforeVideoRecording next={nextCallback} />;
    case 14:
      return <VideoRecording next={nextCallback} />;
    case 15:
      return <ReviewVideoRecording next={nextCallback} prev={prevCallback} />;
    case 16:
      return <ThankYou />;
    case QR_CODE_STEP:
      return (
        <QRCodeStep data={String(window.location)} goBack={backFromQRCode} />
      );
    case READ_TOS_STEP:
      return <ReadTermsAndConditions next={nextCallback} />;
    case READ_GUIDELINES_STEP:
      return <ReadGuidelines next={nextCallback} />;
    default:
      throw new Error('Unknown step');
  }
}

// Reducer for photo retry counter

type RetryState = {
  counter: number;
};

type RetryAction = {
  type: String;
};

function retryReducer(state: RetryState, action: RetryAction) {
  switch (action.type) {
    case 'reset':
      return { counter: 0 };
    case 'increment':
      return { counter: state.counter + 1 };
    default:
      throw new Error();
  }
}

export default function VerificationWizard() {
  const { verificationToken, selectedIdType } = useVerification();
  const { videoStreamError } = useContext(videoStreamStore);
  const [activeStep, setActiveStep] = React.useState(-2);
  const [submitVerificationStep] = useMutation(SAVE_STEP);
  const [photoRetries, photoRetriesIncrement] = React.useReducer(retryReducer, {
    counter: 0,
  });
  useEffect(() => {
    if (activeStep === DOCUMENT_PHOTO_STEP) {
      photoRetriesIncrement({ type: 'increment' });
    }
  }, [activeStep]);

  useEffect(() => {
    if (verificationToken) {
      let stepSlug = getStepSlug(activeStep);
      if (
        !localStorage.getItem(stepSlug) ||
        localStorage.getItem(stepSlug) !== verificationToken.hash
      ) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'stepView',
            step: stepSlug,
          },
        });
        localStorage.setItem(stepSlug, verificationToken.hash);
      }
    }
  }, [activeStep, verificationToken]);

  // Save step to database
  useEffect(() => {
    submitVerificationStep({
      variables: {
        token: verificationToken?.hash,
        step_slug: getStepSlug(activeStep),
      },
    });
    if (
      process.env.NODE_ENV === 'development' &&
      typeof process.env.REACT_APP_STEP_TO !== 'undefined'
    ) {
      const step_to = Number(process.env.REACT_APP_STEP_TO);
      setActiveStep(step_to);
    }
  }, [activeStep, verificationToken, submitVerificationStep]);

  const handleNext = () => {
    let skipSteps = 0;
    // If document has no back skip to selfie step
    if (activeStep === 6 && !selectedIdType?.has_back) {
      skipSteps = 3;
    }
    if (activeStep === -1) {
      // Skip TOS and Privacy
      setActiveStep(2);
    } else {
      setActiveStep(activeStep + 1 + skipSteps);
    }
  };

  const handlePrev = () => {
    // Only go back for DocumentPhotoVerification, DocumentBackPhotoVerification, BeforeSelfiePhoto and VideoRecording
    if ([6, 9, 12, 15].includes(activeStep)) {
      setActiveStep(activeStep - 1);
    }
  };

  const handleQRCode = () => {
    setActiveStep(QR_CODE_STEP);
  };

  const handleReadTermsAndConditions = () => {
    setActiveStep(READ_TOS_STEP);
  };

  const handleReadGuidelines = () => {
    setActiveStep(READ_GUIDELINES_STEP);
  };

  const handleBackFromQRCode = () => {
    setActiveStep(-1);
  };

  if (activeStep === -2) {
    return <CompatibilityTest next={handleNext} />;
  }

  if (videoStreamError instanceof NoWebcam) {
    return <CompatibilityTest next={handleNext} />;
  }

  if (activeStep === -1) {
    return (
      <React.Suspense fallback="Loading">
        <VerificationStart
          next={handleNext}
          qrCodeStep={handleQRCode}
          readTermsAndConditions={handleReadTermsAndConditions}
          readGuidelines={handleReadGuidelines}
        />
      </React.Suspense>
    );
  }

  return (
    <React.Suspense fallback="Loading">
      {getStepContent(
        activeStep,
        handleNext,
        handlePrev,
        handleBackFromQRCode,
        photoRetries.counter
      )}
    </React.Suspense>
  );
}
