import React, { MouseEventHandler, ReactNode } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import NextVerificationButton from '../nextButton';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '100%',
    // Next button at bottom
  },
  containerContent: {
    alignSelf: 'center',
  },
  nextButton: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'flex-start',
  },
  headerText: {
    fontSize: '1.7rem',
    color: '#475569',
  },
}));

type VerificationContainerProps = {
  next: MouseEventHandler;
  prev?: MouseEventHandler;
  otherActionButton?: ReactNode;
  headerText: string;
  captionText?: string;
  prevButtonText?: string;
  buttonText: string;
  buttonDisabled: boolean;
  buttonHidden?: boolean;
  children: ReactNode;
  loading?: boolean;
  loadingText?: string;
  stretch?: boolean;
};

export default function VerificationContainer({
  next,
  prev,
  prevButtonText,
  headerText,
  captionText,
  buttonText,
  buttonDisabled,
  buttonHidden,
  otherActionButton,
  loading,
  loadingText,
  children,
  stretch,
}: VerificationContainerProps) {
  const { t } = useTranslation(['app']);

  const classes = useStyles();

  let prevButton: JSX.Element | null = null;

  if (prev) {
    prevButton = (
      <NextVerificationButton
        text={prevButtonText ? prevButtonText : t('app:buttonRetake', 'Retake')}
        onClick={prev}
        disabled={loading}
        loading={loading}
      />
    );
  }

  return (
    <Grid
      container
      className={classes.container}
      spacing={2}
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item>
        <Typography
          className={classes.headerText}
          component="div"
          variant={'h5'}
          align={'center'}
        >
          <Box fontWeight="fontWeightBold">{headerText}</Box>
        </Typography>
      </Grid>
      <Grid item>
        <Typography variant="subtitle1" align={'center'}>
          {captionText}
        </Typography>
      </Grid>
      {React.Children.map(children, (child) => {
        if (!child) return null;
        return (
          <Grid className={clsx({ [classes.containerContent]: stretch })} item>
            {child}
          </Grid>
        );
      })}
      <Grid item className={classes.nextButton}>
        {loading ? (
          <Grid
            container
            direction="row"
            alignItems={'center'}
            alignContent={'center'}
          >
            <CircularProgress />
            <Typography style={{ paddingLeft: '1em' }}>
              {loadingText}
            </Typography>
          </Grid>
        ) : (
          <React.Fragment>
            {prevButton}
            {otherActionButton}
            {!buttonHidden && (
              <NextVerificationButton
                text={buttonText}
                onClick={next}
                disabled={buttonDisabled}
                loading={loading}
              />
            )}
          </React.Fragment>
        )}
      </Grid>
    </Grid>
  );
}
