import React, { useContext, useEffect } from 'react';
import { videoStreamStore } from '../../../stores/videoContext';
import VerificationContainer from '../container/index.js';
import selfieSvg from '../../../assets/images/selfie.svg';
import AllowCameraUseError from '../../cameraPermissionsError';
import { videoWrapperStyles } from '../../cameraPreview/styles';

import { useTranslation } from 'react-i18next';

type AllowCameraPermissionsProps = {
  next: Function;
};

export default function BeforeSelfiePhoto({
  next,
}: AllowCameraPermissionsProps) {
  const { t } = useTranslation(['app']);

  const {
    videoStream,
    startVideoStream,
    hasMultipleCameras,
    videoStreamError,
  } = useContext(videoStreamStore);
  const classes = videoWrapperStyles();

  useEffect(() => {
    if (hasMultipleCameras()) {
      startVideoStream(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const validate = () => {
    // TODO: Validate
    next();
  };

  return (
    <VerificationContainer
      next={validate}
      headerText={t('app:beforeSelfiePhotoTitle', 'Take a selfie')}
      captionText={t(
        'app:beforeSelfiePhotoText',
        'Get ready to snap a photo of yourself'
      )}
      buttonText={t('app:buttonContinue', 'Continue')}
      buttonDisabled={!videoStream}
      stretch
    >
      {videoStreamError && <AllowCameraUseError />}
      <img src={selfieSvg} className={classes.placeHolderImage} alt={''} />{' '}
    </VerificationContainer>
  );
}
