import React, { useContext, useRef } from 'react';
import { videoStreamStore } from '../../../stores/videoContext';
import { useVerification } from '../../../stores/verificationContext';
import SwitchCameraIcon from '@material-ui/icons/FlipCameraIosRounded';
import { videoWrapperStyles } from '../../cameraPreview/styles';
import clsx from 'clsx';
import VerificationContainer from '../container/index.js';

import { useTranslation } from 'react-i18next';

type SelfieVerificationProps = {
  next: Function;
};

export default function SelfieVerification({ next }: SelfieVerificationProps) {
  const { t } = useTranslation(['app']);

  const {
    videoStream,
    getSnapShot,
    switchCamera,
    hasMultipleCameras,
    isCameraFacingUser,
  } = useContext(videoStreamStore);
  const classes = videoWrapperStyles();
  const mediaStreamRef = useRef(null);
  let videoElement: HTMLVideoElement | null = null;
  const { setSelfiePhotoBlob } = useVerification();

  const addMediaStream = (video: HTMLVideoElement) => {
    // Video stream should be already available from the previous step no need
    // handle errors here
    if (videoStream && video) {
      video.srcObject = videoStream;
      videoElement = video;
    }
  };

  const validate = () => {
    if (videoElement) {
      // Create Selfie Photo Blob;
      getSnapShot(videoElement).then((selfieBlob: Blob) => {
        setSelfiePhotoBlob(selfieBlob);
        next();
      });
    }
  };

  return (
    <VerificationContainer
      next={validate}
      headerText={t('app:selfieTitle', 'Take a selfie')}
      captionText={t(
        'app:selfieText',
        'Look in to the camera and make sure your face is clearly visible.'
      )}
      buttonText={t('app:buttonTakeASelfie', 'Take a selfie')}
      buttonDisabled={!videoStream}
      stretch
    >
      <div className={classes.videoWrapperSelfie}>
        <span className={classes.faceOverlay}></span>
        <video
          width={'100%'}
          height={'100%'}
          playsInline
          autoPlay
          className={clsx(classes.video, {
            [classes.mirrorVideo]: isCameraFacingUser(),
          })}
          ref={mediaStreamRef ? addMediaStream : null}
        />
        {hasMultipleCameras() && (
          <SwitchCameraIcon
            className={classes.switchCameraCenter}
            onClick={() => switchCamera()}
          />
        )}
      </div>
    </VerificationContainer>
  );
}
