import { makeStyles } from '@material-ui/core/styles';

export const videoWrapperStyles = makeStyles((theme) => ({
  videoWrapper: {
    maxHeight: '392px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '16px',
    [theme.breakpoints.up('md')]: {
      maxHeight: '373px',
    },
  },
  documentOverlay: {
    position: 'absolute',
    width: '88%',
    height: '64%',
    border: '6px solid #63bc96',
    left: '6%',
    top: '18%',
    boxShadow: '0 0 0 1000px rgba(0,0,0,0.6)',
    borderRadius: '16px',
    zIndex: 1,
    '&.video': {
      top: '33%',
      left: '18%',
      width: '76%',
      height: '33%',
    },
    [theme.breakpoints.up('md')]: {
      top: '8%',
      height: '84%',
      left: '12%',
      width: '76%',
    },
    [theme.breakpoints.down('md')]: {
      '&.video': {
        top: '30%',
        left: '6%',
        width: '94%',
        height: '33%',
      },
    },
  },
  faceOverlay: {
    position: 'absolute',
    boxShadow: '0 0 0 1000px rgba(0,0,0,0.6)',
    top: '13%',
    border: '6px solid #63bc96',
    left: '25%',
    width: '50%',
    height: '76%',
    borderRadius: '50% 50% 50% 50% / 38% 38% 66% 66%',
    zIndex: 1,
    '&.video': {
      top: '14%',
      left: '7%',
      width: '69%',
      height: '75%',
    },
    [theme.breakpoints.up('md')]: {
      top: '12%',
      left: '20%',
      width: '60%',
      height: '76%',
    },
    [theme.breakpoints.down('xs')]: {
      '&.video': {
        top: '16%',
        left: '10%',
        width: '80%',
        height: '71%',
      },
    },
  },
  left: {
    position: 'absolute',
    width: '40%',
    height: '100%',
    overflow: 'hidden',
  },
  right: {
    position: 'absolute',
    width: '60%',
    height: '100%',
    left: '40%',
    overflow: 'hidden',
  },
  videoWrapperSelfie: {
    height: '355px',
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '16px',
    [theme.breakpoints.up('md')]: {
      height: '552px',
    },
  },
  videoWrapperSelfieWithDoc: {
    position: 'relative',
    overflow: 'hidden',
    borderRadius: '16px',
  },

  documentOutline: {
    border: 'solid 6px #63bc96',
    borderRadius: '15px',
    position: 'absolute',
    boxSizing: 'border-box',
    display: 'block',
    margin: 0,
    padding: 0,
    minHeight: '1em',
    top: '40%',
    width: '25%',
    height: '17%',
    left: '10%',
  },
  w100: {
    width: '100%',
  },
  video: {
    objectFit: 'cover',
    borderRadius: '16px',
  },
  guideImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    backgroundColor: '#F6F6F6',
    borderRadius: '16px',
    [theme.breakpoints.up('md')]: {
      width: '552px',
    },
  },
  placeHolderImage: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    backgroundColor: '#F6F6F6',
    borderRadius: '16px',
  },
  placeHolderImageMirrored: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
    backgroundColor: '#F6F6F6',
    borderRadius: '16px',
    transform: 'rotateY(180deg)',
    '-webkit-transform': 'rotateY(180deg);' /* Safari and Chrome */,
    '-moz-transform': 'rotateY(180deg);' /* Firefox */,
    [theme.breakpoints.up('md')]: {
      height: '552px',
    },
  },
  switchCameraRight: {
    position: 'absolute',
    bottom: 10,
    right: 20,
    color: '#fff',
    fontSize: '45px',
    zIndex: 2,
  },
  switchCameraCenter: {
    position: 'absolute',
    bottom: 10,
    top: '1%',
    right: '1%',
    color: '#fff',
    fontSize: '45px',
    zIndex: 2,
  },
  mirrorVideo: {
    transform: 'rotateY(180deg)',
    '-webkit-transform': 'rotateY(180deg);' /* Safari and Chrome */,
    '-moz-transform': 'rotateY(180deg);' /* Firefox */,
  },
}));
