/* eslint-disable no-use-before-define */
import React from 'react';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { CountryType } from '../verification/countrySelect/types';

import { countryTranslation } from '../../i18next';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode: string) {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397)
        )
    : isoCode;
}

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
  autocomplete: {
    width: '100%',
  },
});

type CountrySelectProps = {
  countries: CountryType[];
  selectedCountry: CountryType | null;
  onSelect?: Function;
  inputLabel?: String;
};

export default function CountrySelector({
  countries,
  selectedCountry,
  onSelect,
  inputLabel,
}: CountrySelectProps) {
  const classes = useStyles();

  return (
    <Autocomplete
      id="country-select-demo"
      options={countries as CountryType[]}
      className={classes.autocomplete}
      classes={{
        option: classes.option,
      }}
      // open={!selectedCountry}
      autoSelect={true}
      autoHighlight
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <React.Fragment>
          <span>{countryToFlag(option.code)}</span>
          {countryTranslation(option.code, option.name)} ({option.code})
        </React.Fragment>
      )}
      value={selectedCountry || null}
      onChange={(event: React.ChangeEvent<{}>, value: CountryType | null) => {
        if (onSelect) {
          onSelect(value);
        }
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={inputLabel || 'Choose a country'}
          variant="outlined"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'off', // disable autocomplete and autofill
          }}
        />
      )}
    />
  );
}
