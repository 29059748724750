import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { NextStepButtonProps } from './types';
import Button from '@material-ui/core/Button';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  buttons: {
    display: 'flex',
    justifyContent: 'center',
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    fontWeight: 'bold',
    textTransform: 'none',
    color: 'white',
  },
}));

export default function NextVerificationButton({
  text,
  loading,
  disabled,
  onClick,
}: NextStepButtonProps) {
  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.buttons}>
        <div className={classes.wrapper}>
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={onClick}
            disabled={disabled || loading}
          >
            {text}
          </Button>
          {loading && (
            <CircularProgress size={24} className={classes.buttonProgress} />
          )}
        </div>
      </div>
    </React.Fragment>
  );
}
