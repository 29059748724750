enum cameraFacing {
  environment = 'environment',
  user = 'user',
}

const filterUniqueVideoDevices = (
  devices: MediaDeviceInfo[]
): MediaDeviceInfo[] => {
  // alert('Device 1:' + devices[0].kind);
  // alert('Device 1 id:' + devices[0].deviceId);
  // alert('Device 2:' + devices[1].kind);
  // alert('Device 2 id:' + devices[1].deviceId);
  // alert('Device 3:' + devices[2].kind);
  // alert('Device 3 id:' + devices[2].deviceId);
  // alert('Device 4:' + devices[3].kind);
  // alert('Device 4 id:' + devices[3].deviceId);
  // alert('Device 5:' + devices[4].kind);
  // alert('Device 5 id:' + devices[4].deviceId);
  // alert('Device 6:' + devices[5].kind);
  // alert('Device 6 id:' + devices[5].deviceId);

  return devices
    .filter((device) => device.kind === 'videoinput')
    .filter(
      (device, i, arr) =>
        arr.findIndex((t) => t.deviceId === device.deviceId) === i
    );
};

const getMediaConstrains = (
  shouldFaceUser: boolean,
  specificDevice: MediaDeviceInfo | null = null
): MediaStreamConstraints => {
  let constrainObject: MediaTrackConstraints = {
    facingMode: shouldFaceUser ? cameraFacing.user : cameraFacing.environment,
  };
  if (specificDevice) {
    constrainObject.deviceId = specificDevice.deviceId;
  }
  constrainObject.width = { min: 480, ideal: 1600, max: 1600 };
  constrainObject.height = { min: 480, ideal: 1920, max: 1920 };
  return {
    audio: false,
    video: {
      ...constrainObject,
    },
  };
};

const getMediaRecorderOptions = () => {
  const videoBitsPerSecond = 1000000;
  const mimeTypes: string[] = [
    'video/webm;codecs:vp9',
    'video/webm;codecs:h265',
    'video/webm',
    'video/mp4',
  ];

  while (mimeTypes.length) {
    const mimeType = mimeTypes.pop() as string;
    if (MediaRecorder.isTypeSupported(mimeType)) {
      return { mimeType: mimeType, videoBitsPerSecond };
    }
  }

  return { mimeType: '', videoBitsPerSecond };
};

const getMediaRecorderOptionsMemeType = () => {
  return getMediaRecorderOptions().mimeType.split(';')[0];
};

const getMediaRecorderOptionsExtension = () => {
  return getMediaRecorderOptionsMemeType().split('/')[1];
};

const iOSversion = () => {
  if (/iP(hone|od|ad)/.test(navigator.platform)) {
    // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
    var v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
    return [
      parseInt((v ?? ['0', '0', '0'])[1] || '0', 10),
      parseInt((v ?? ['0', '0', '0'])[2] || '0', 10),
      parseInt((v ?? ['0', '0', '0'])[3] || '0', 10),
    ];
  }
  return [0, 0, 0];
};

const isiOS = (): boolean => {
  const iDevices = [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ];

  if (navigator.platform) {
    while (iDevices.length) {
      if (navigator.platform === iDevices.pop()) {
        if (navigator.platform === 'iPhone') {
          let versions = iOSversion();
          if (versions[0] > 14) {
            return false;
          }
          if (versions[0] === 14 && versions[1] >= 8) {
            return false;
          }
        }
        return true;
      }
    }
  }

  return false;
};

const hasUserMediasAPI = (): boolean => !!navigator.mediaDevices;
const hasMediaRecorderAPI = (): boolean => !!window.MediaRecorder && !isiOS();
const hasWebRtcApi = (): boolean => !!window.RTCPeerConnection;

export {
  filterUniqueVideoDevices,
  getMediaConstrains,
  cameraFacing,
  hasUserMediasAPI,
  hasMediaRecorderAPI,
  hasWebRtcApi,
  isiOS,
  getMediaRecorderOptions,
  getMediaRecorderOptionsMemeType,
  getMediaRecorderOptionsExtension,
};
