import 'core-js';
import 'isomorphic-unfetch';
import 'webrtc-adapter';

import './i18next';

import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from '@apollo/react-hooks';
import { client } from './configs/apollo';
import './index.css';
import App from './App';
import {
  createMuiTheme,
  responsiveFontSizes,
  ThemeProvider,
} from '@material-ui/core/styles';
import 'typeface-nunito';

import * as Sentry from '@sentry/react';
import TagManager from 'react-gtm-module';

Sentry.init({
  dsn:
    'https://1dd051f0436f45b0b58dfb03ab9bf5e0@o354248.ingest.sentry.io/5424584',
  environment: process.env.REACT_APP_ENV,
});

let theme = createMuiTheme({
  typography: {
    fontFamily: 'Nunito Sans, Roboto, sans-serif',
    subtitle1: {
      color: '#505366',
      fontSize: '0.9rem',
    },
  },
  palette: {
    primary: {
      main: '#63bc96',
    },
    secondary: {
      main: '#63bc96',
    },
    background: {
      default: '#ffffff',
    },
    error: {
      main: '#f05a7e',
    },
  },
});
theme = responsiveFontSizes(theme);

const tagManagerArgs = {
  gtmId: 'GTM-MFXC8KG',
  events: {
    stepView: 'View step',
  },
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occured'}>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ApolloProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);
