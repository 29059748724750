import React, { useState, useEffect, useMemo } from 'react';
import {
  Grid,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Typography,
  FormControlLabel,
  Checkbox,
  Link,
} from '@material-ui/core';
import NextVerificationButton from '../nextButton';
import logo from '../../../assets/images/logo.png';
import startSvg from '../../../assets/images/start.svg';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';

import { useTranslation } from 'react-i18next';
import { SUPPORTED_LANGUAGES, languageTranslation } from '../../../i18next';
import { useVerification } from '../../../stores/verificationContext';
import renderHTML from 'react-render-html';

type VerificationStartProps = {
  next: Function;
  qrCodeStep: Function;
  readTermsAndConditions: Function;
  readGuidelines: Function;
};

declare global {
  interface Window {
    dataLayer: any;
  }
}

const useStyles = makeStyles((theme) => ({
  image: {
    maxHeight: '240px',
    height: '240px',
    width: '100%',
    objectFit: 'cover',
    borderRadius: 16,
  },
  companyLogo: {
    maxHeight: '240px',
    paddingBottom: 15,
    height: '80%',
    width: '80%',
  },
  startOutlined: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: '5px',
    margin: '20px 4px 20px 4px',
    padding: '20px',
    width: '100%',
  },
  tosContainer: {
    width: '80%',
    margin: '0px auto 0px auto',
  },
  acceptTosLabel: {
    fontSize: '0.875rem',
  },
  acceptGuidelinesLabel: {
    fontSize: '0.875rem',
  },
  warningNotification: {
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderColor: '#f1972d',
    borderRadius: '10px',
    margin: '0px 4px 15px 4px',
    padding: '10px',
    backgroundColor: '#fff5e7',
  },
}));

export default function VerificationStart({
  next,
  readTermsAndConditions,
  readGuidelines,
  qrCodeStep,
}: VerificationStartProps) {
  const classes = useStyles();

  const { verificationToken } = useVerification();
  const { t, i18n } = useTranslation(['app']);

  const handleChangeLanguage = (e: any) => {
    localStorage.setItem('languageSelector', e.target.value);
    i18n.changeLanguage(e.target.value);
  };

  const validate = () => {
    next();
  };

  const scanQR = () => {
    qrCodeStep();
  };

  const [tosAccepted, setTosAccepted] = useState(false);

  const changeTos = (event) => {
    setTosAccepted(event.target.checked);
    const isTosAccepted = !tosAccepted ? 'yes' : 'no';
    localStorage.setItem('tosAccepted', isTosAccepted);
  };

  const [guidelinesAccepted, setGuidelinesAccepted] = useState(false);

  const changeGuidelines = (event) => {
    setGuidelinesAccepted(event.target.checked);
    const isGuidelinesAccepted = !tosAccepted ? 'yes' : 'no';
    localStorage.setItem('guidelinesAccepted', isGuidelinesAccepted);
  };

  useEffect(() => {
    let lng = localStorage.getItem('languageSelector');
    if (lng) {
      i18n.changeLanguage(lng);
    } else if (
      verificationToken?.language &&
      i18n?.options.supportedLngs &&
      i18n?.options.supportedLngs.includes(verificationToken.language)
    ) {
      i18n.changeLanguage(verificationToken.language);
    }

    const storedTosAccepted =
      localStorage.getItem('tosAccepted') === 'yes' ? true : false;
    const storedGuidelinesAccepted =
      localStorage.getItem('guidelinesAccepted') === 'yes' ? true : false;
    setTosAccepted(storedTosAccepted);
    setGuidelinesAccepted(storedGuidelinesAccepted);
  }, [i18n, verificationToken]);

  const companyNotification = useMemo(() => {
    if (!verificationToken?.notification) {
      return null;
    }
    let locale = i18n.language.replace(/-/g, '');
    if (
      verificationToken?.notification.hasOwnProperty(locale) &&
      verificationToken?.notification[locale]
    ) {
      return verificationToken?.notification[locale];
    }
    if (
      verificationToken?.notification.hasOwnProperty('en') &&
      verificationToken?.notification['en']
    ) {
      return verificationToken?.notification['en'];
    }
    return null;
  }, [i18n.language]);

  const openPageTos = () => {
    readTermsAndConditions();
  };

  const openPageGuidelines = () => {
    readGuidelines();
  };

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item sm={12}>
        <img src={logo} alt="evalid" height={70} />
      </Grid>
      <Grid item style={{ alignSelf: 'stretch' }}>
        <img src={startSvg} className={classes.image} alt={''} />
      </Grid>
      <Grid item style={{ alignSelf: 'stretch' }}>
        <FormControl variant="outlined" fullWidth={true}>
          <InputLabel htmlFor="language">
            {t('app:selectLanguageLabel', 'Select Language')}
          </InputLabel>
          <Select
            id="language"
            value={i18n.language}
            onChange={handleChangeLanguage}
            label={t('app:selectLanguageLabel', 'Select Language')}
          >
            {SUPPORTED_LANGUAGES.map((lng) => (
              <MenuItem value={lng[0]} key={lng[0]}>
                {t('app:' + languageTranslation(lng[0]), lng[1])}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      {verificationToken?.show_info ? (
        <Grid
          className={classes.startOutlined}
          style={{ alignSelf: 'stretch' }}
        >
          <Grid item>
            <Typography
              align="center"
              style={{ paddingBottom: 15, paddingTop: 10 }}
            >
              {t(
                'app:verificationInitializedBy',
                'This verification is initiated through'
              )}
              &nbsp;
              <Link
                href={verificationToken?.company_website}
                target="_blank"
                rel="noopener"
                color="primary"
                underline="always"
              >
                {verificationToken?.company_website}
              </Link>
            </Typography>
          </Grid>
          <Grid
            container
            style={{ alignSelf: 'stretch', width: '100%' }}
            alignItems="center"
            justify="center"
          >
            <img
              src={verificationToken?.company_logo}
              alt="Company logo"
              className={classes.companyLogo}
            />
          </Grid>
          {companyNotification ? (
            <Grid
              item
              className={classes.warningNotification}
              alignItems="center"
              justify="center"
            >
              {renderHTML(companyNotification)}
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      ) : (
        ''
      )}
      <div className={classes.startOutlined}>
        <Grid item>
          <List dense>
            <ListItem disableGutters>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText
                primary={t(
                  'app:startItem1',
                  ' Make sure you have a valid identity document ready'
                )}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText
                primary={t('app:startItem2', 'Check your camera is working')}
              />
            </ListItem>
            <ListItem disableGutters>
              <ListItemIcon>
                <CheckIcon />
              </ListItemIcon>
              <ListItemText
                primary={t(
                  'app:startItem3',
                  'Get ready to take photos of your ID, a selfie, and perform a liveness detection check.'
                )}
              />
            </ListItem>
          </List>
        </Grid>
      </div>

      <div className={classes.tosContainer}>
        <FormControl fullWidth={false}>
          <FormControlLabel
            key="acceptTosLabel{Math.random().toString(36).substr(2, 5)}"
            control={
              <Checkbox
                id="acceptTos"
                className="acceptTos"
                name="acceptTos"
                checked={tosAccepted}
                onChange={changeTos}
              />
            }
            label={[
              <div className={classes.acceptTosLabel}>
                {[
                  t('app:tosLabelReadTos', 'I have read and understand'),
                  ' ',
                  <Link href="#" onClick={openPageTos}>
                    {t('app:tosLabelTos', 'Terms of use and Privacy Policy')}
                  </Link>,
                  t('app:tosLabelTosAfter', '')
                    ? ' ' + t('app:tosLabelTosAfter', '')
                    : '',
                  '.',
                ]}
              </div>,
            ]}
          />
          <FormControlLabel
            key="acceptGuidelinesLabel{Math.random().toString(36).substr(2, 5)}"
            control={
              <Checkbox
                id="acceptGuidelines"
                className="acceptGuidelines"
                name="acceptGuidelines"
                checked={guidelinesAccepted}
                onChange={changeGuidelines}
              />
            }
            label={[
              <div className={classes.acceptGuidelinesLabel}>
                {[
                  t('app:tosLabelReadGuidelines', 'I have read the'),
                  ' ',
                  <Link href="#" onClick={openPageGuidelines}>
                    {t('app:tosLabelGuidelines', 'Usage guidelines')}
                  </Link>,
                  t('app:tosLabelGuidelinesAfter', '')
                    ? ' ' + t('app:tosLabelTosAfter', '')
                    : '',
                  '.',
                ]}
              </div>,
            ]}
          />
        </FormControl>
      </div>

      <Grid item>
        <NextVerificationButton
          text={t('app:buttonStartVerification', 'Start Verification')}
          onClick={validate}
          disabled={!(tosAccepted && guidelinesAccepted)}
        />
      </Grid>

      <Grid item className="full-width">
        <Typography variant="body2" align="center">
          {t(
            'app:startItem4',
            'No camera? Scan QR code with your phone to continue.'
          )}
        </Typography>
      </Grid>
      <Grid item>
        <NextVerificationButton
          text={t('app:buttonScanQR', 'Scan QR Code')}
          onClick={scanQR}
          disabled={false}
        />
      </Grid>
    </Grid>
  );
}
