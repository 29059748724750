// Edge canvas polyfill
import 'blueimp-canvas-to-blob';
import React from 'react';
import './App.css';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import Main from './screens/main';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import ErrorScreen from './screens/error';

const useStyles = makeStyles((theme) => ({
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(1),
    padding: theme.spacing(0),
    [theme.breakpoints.up(700 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(3),
    },
    '@media (min-height:1024px)': {
      height: 'calc(var(--vh, 1vh) * 100)',
    },
  },
}));

function App() {
  const classes = useStyles();

  return (
    <Router>
      <React.Fragment>
        <CssBaseline />
        <main className={classes.layout}>
          <Box className={classes.paper}>
            <Switch>
              <Route path="/token/:token" children={<Main />} />
              <Route path="/error">
                <ErrorScreen />
              </Route>
              <Route path="/">
                <Redirect to="/error" />
              </Route>
              <Route path="*">
                <Redirect to="/error" />
              </Route>
            </Switch>
          </Box>
        </main>
      </React.Fragment>
    </Router>
  );
}

export default App;
