import React from 'react';
import { Typography } from '@material-ui/core';

function ErrorScreen() {
  return (
    <Typography variant="h1" color={'error'} align={'center'}>
      Error verifying
    </Typography>
  );
}

export default ErrorScreen;
