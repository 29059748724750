import React from 'react';
import VerificationWizard from '../verification';
import { gql } from 'apollo-boost';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import SplashScreen from '../spalsh';
import { VerificationStatus } from '../../types';
import ErrorScreen from '../error';
import ApprovedScreen from '../approved';
import RejectedScreen from '../rejected';
import PendingScreen from '../pending';
import { ApiTokenVars, VerificationResponse, VerificationToken } from './types';
import { VerificationTokenProvider } from './VerificationTokenContext';
import { VerificationProvider } from '../../stores/verificationContext';
import { VideoStreamProvider } from '../../stores/videoContext';
import { SUPPORTED_LANGUAGES } from '../../i18next';

const languageCodes = (): Array<string> =>
  SUPPORTED_LANGUAGES.reduce(
    (accumulator: Array<string>, item: Array<string>) => {
      accumulator.push(item[0].replace(/-/g, ''));
      return accumulator;
    },
    []
  );

const GET_VERFICATION = gql`
  query($token: String) {
    verification(hash: $token) {
      hash
      language
      status
      has_parent
      terms_accepted
      redirect_url
      phone_number_required
      first_name
      middle_name
      last_name
      email
      date_of_birth
      gender
      phone_number
      phone_verified
      country {
        id
        code
        name
        idTypes {
          id
          name
          has_back
        }
      }
      nationality {
        id
        code
        name
        idTypes {
          id
          name
          has_back
        }
      }
      document_type {
        id
        name
        has_back
      }
      show_info
      company_logo
      notification {
        ${languageCodes().join(' ')}
      }
      company_website
    }
  }
`;

// Show the correct page based on the verification status
function showVerificationStatus(apiVerification: VerificationToken) {
  if (apiVerification.status === VerificationStatus.new) {
    return <VerificationWizard />;
  }

  if (apiVerification.status === VerificationStatus.approved) {
    return <ApprovedScreen />;
  }

  if (apiVerification.status === VerificationStatus.rejected) {
    return <RejectedScreen />;
  }

  if (
    apiVerification.status === VerificationStatus.pending ||
    apiVerification.status === VerificationStatus.submitted
  ) {
    return <PendingScreen />;
  }

  return <ErrorScreen />;
}

function Main() {
  let { token } = useParams();

  const { loading, error, data } = useQuery<VerificationResponse, ApiTokenVars>(
    GET_VERFICATION,
    {
      variables: { token: token || '' },
    }
  );

  if (loading) return <SplashScreen />;
  if (error) return <ErrorScreen />;

  if (data && data.verification) {
    const { verification: apiVerification } = data;
    const verificationTokenProvider = {
      verification: apiVerification,
    };

    return (
      <VerificationProvider verification={apiVerification}>
        <VideoStreamProvider>
          <VerificationTokenProvider value={verificationTokenProvider}>
            {showVerificationStatus(apiVerification)}
          </VerificationTokenProvider>
        </VideoStreamProvider>
      </VerificationProvider>
    );
  }

  return <ErrorScreen />;
}

export default Main;
