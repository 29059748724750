import React, { useContext, useEffect, useState } from 'react';
import { videoStreamStore } from '../../../stores/videoContext';
import VerificationContainer from '../container/index.js';
import AllowCameraUseError from '../../cameraPermissionsError';
import { videoWrapperStyles } from '../../cameraPreview/styles';

import { useVerification } from '../../../stores/verificationContext';
import { useTranslation } from 'react-i18next';
import selfiecheck from '../../../lib/selfiecheck';
import { Grid, Typography } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';

type ReviewSelfieVerificationProps = {
  next: Function;
  prev: Function;
  retries: Number;
  maxRetries: Number;
};

type ErrorsType = { type: string; code: number; message: string };

export default function ReviewSelfieVerification({
  next,
  prev,
  retries,
  maxRetries,
}: ReviewSelfieVerificationProps) {
  const { t } = useTranslation(['app']);

  const [hasBeenAnalysed, setHasBeenAnalysed] = useState(false);
  const [isBusy, setIsBusy] = useState(false);
  const [errors, setErrors] = useState<ErrorsType[]>([]);
  const { verificationToken } = useVerification();

  const { videoStream, videoStreamError } = useContext(videoStreamStore);

  const { selfiePhotoBlob } = useVerification();
  let documentPhotoImg = '';
  if (selfiePhotoBlob.size > 0 && selfiePhotoBlob.type === 'image/jpeg') {
    documentPhotoImg = URL.createObjectURL(selfiePhotoBlob);
  }

  const classes = videoWrapperStyles();

  const goForward = () => {
    next();
  };

  const goBack = () => {
    prev();
  };

  useEffect(() => {
    if (selfiePhotoBlob && !hasBeenAnalysed) {
      selfiecheck(verificationToken, selfiePhotoBlob, {})
        .then((response) => response.data)
        .then((data) => {
          setErrors(data);
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsBusy(false);
        });

      setHasBeenAnalysed(true);
      setIsBusy(true);
    }
  }, [
    selfiePhotoBlob,
    verificationToken,
    hasBeenAnalysed,
    isBusy,
    setHasBeenAnalysed,
    setIsBusy,
    setErrors,
  ]);

  let continueDisabled = true;

  let error: any = null;
  if (!isBusy && hasBeenAnalysed) {
    if (errors && errors.length > 0) {
      if (retries < maxRetries) {
        continueDisabled = true;
      }

      if (errors.some((error) => error.type === 'FACE_NOT_FOUND_FAIL')) {
        error = t(
          'app:errorBlurSelfie',
          'The selfie you have taken is blurry. Please take another selfie.'
        );
      } else if (errors.some((error) => error.type === 'FACE_NOT_FOUND_FAIL')) {
        error = t(
          'app:errorFaceNotFoundText',
          "We couldn't find a face in the image you have taken. Please take another selfie."
        );
      }
      error = (
        <Grid
          container
          alignItems={'center'}
          alignContent={'center'}
          justify={'center'}
        >
          <Typography
            color={'error'}
            style={{
              alignItems: 'center',
              display: 'flex',
            }}
          >
            <CancelIcon style={{ marginRight: '1em' }} color={'error'} />
            {error}
          </Typography>
        </Grid>
      );
    } else {
      continueDisabled = !videoStream || false;
    }
  }

  return (
    <VerificationContainer
      loading={isBusy}
      loadingText={t('app:analyzingSelfie', 'Analyzing selfie')}
      next={goForward}
      prev={goBack}
      prevButtonText={
        error
          ? t('app:retakeSelfie', 'Retake selfie')
          : t('app:noRetake', 'No, retake')
      }
      headerText={t('app:reviewSelfieVerificationTitle', 'Selfie verification')}
      buttonText={t('app:yesContinue', 'Yes, continue')}
      buttonDisabled={continueDisabled}
      buttonHidden={error}
      stretch
    >
      {videoStreamError && <AllowCameraUseError />}
      {documentPhotoImg && (
        <React.Fragment>
          <img
            src={documentPhotoImg}
            className={classes.placeHolderImageMirrored}
            alt={''}
          />
        </React.Fragment>
      )}
      {error}
      {!isBusy && hasBeenAnalysed && error == null && (
        <Grid alignItems={'center'} alignContent={'center'}>
          <Typography>
            {t(
              'app:isYourFaceFullyAndClearlyVisible',
              'Is your face fully and clearly visible ?'
            )}
          </Typography>
        </Grid>
      )}
    </VerificationContainer>
  );
}
