import React, { useContext, useEffect } from 'react';
import { videoStreamStore } from '../../../stores/videoContext';
import VerificationContainer from '../container/index.js';
import recordingSvg from '../../../assets/images/recording.svg';
import AllowCameraUseError from '../../cameraPermissionsError';
import { videoWrapperStyles } from '../../cameraPreview/styles';

import { useTranslation } from 'react-i18next';

type BeforeVideoRecordingProps = {
  next: Function;
};

export default function BeforeVideoRecording({
  next,
}: BeforeVideoRecordingProps) {
  const { t } = useTranslation(['app']);

  const { videoStream, videoStreamError, startVideoStream } = useContext(
    videoStreamStore
  );
  const classes = videoWrapperStyles();

  useEffect(() => {
    startVideoStream();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goForward = () => {
    next();
  };

  return (
    <VerificationContainer
      next={goForward}
      headerText={t('app:beforeVideoRecordingTitle', 'Video recording')}
      captionText={t(
        'app:beforeVideoRecordingText',
        'Look in to camera, hold your document next to your face and smile.'
      )}
      buttonText={t('app:buttonContinue', 'Continue')}
      buttonDisabled={!videoStream}
      stretch
    >
      {videoStreamError && <AllowCameraUseError />}
      <img src={recordingSvg} className={classes.placeHolderImage} alt={''} />
    </VerificationContainer>
  );
}
