import React, { useContext, useRef, useState } from 'react';
import { videoStreamStore } from '../../../stores/videoContext';
import VerificationContainer from '../container/index.js';
import AllowCameraUseError from '../../cameraPermissionsError';
import { videoWrapperStyles } from '../../cameraPreview/styles';
import { useVerification } from '../../../stores/verificationContext';
import clsx from 'clsx';
import * as Sentry from '@sentry/react';

import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

type ReviewVideoRecordingProps = {
  next: Function;
  prev: Function;
};

export default function ReviewVideoRecording({
  next,
  prev,
}: ReviewVideoRecordingProps) {
  const { t, i18n } = useTranslation(['app']);

  const {
    documentPhotoBlob,
    documentBackPhotoBlob,
    selfiePhotoBlob,
    submitVerification,
  } = useVerification();

  const { videoStreamError, isCameraFacingUser, getRecordingBlob } = useContext(
    videoStreamStore
  );

  const [loading, setLoading] = useState<boolean>(false);
  const videoBlob = getRecordingBlob();

  const goForward = () => {
    setLoading(true);
    submitVerification(
      documentPhotoBlob,
      documentBackPhotoBlob,
      selfiePhotoBlob,
      videoBlob,
      i18n.language
    )
      // Go to next screen
      .then((r) => {
        setLoading(false);
        next();
      })
      .catch((e) => {
        Sentry.captureException(e);
        setLoading(false);
        // TODO: handle error
        console.error(e);
      });
  };

  const mediaStreamRef = useRef(null);

  const addMediaStream = (video: HTMLVideoElement) => {
    // Video stream should be already available from the previous step no need
    // handle errors here
    if (videoBlob.size && video) {
      video.src = URL.createObjectURL(videoBlob);
    }
  };

  const classes = videoWrapperStyles();

  const goBack = () => {
    prev();
  };

  // Used to determine CSS classes
  const cameraFacingUser = isCameraFacingUser();

  return (
    <VerificationContainer
      next={goForward}
      prev={goBack}
      headerText={t('app:livelinessVerification', 'Liveliness Verification')}
      buttonText={t('app:yesFinish', 'Yes, finish')}
      prevButtonText={t('app:noRetake', 'No, retake')}
      buttonDisabled={false}
      loading={loading}
      stretch
    >
      {videoStreamError && <AllowCameraUseError />}
      <div
        className={`${classes.videoWrapperSelfieWithDoc}`}
        hidden={!videoBlob.size}
      >
        <video
          width={'100%'}
          height={'100%'}
          playsInline
          autoPlay
          loop
          ref={mediaStreamRef ? addMediaStream : null}
          className={clsx(classes.video, {
            [classes.mirrorVideo]: cameraFacingUser,
          })}
        />
      </div>
      <Grid alignItems={'center'} alignContent={'center'}>
        <Typography>
          {t(
            'app:isYourFaceAndIdDocumentFullyAndClearlyVisible',
            'Is your face and ID document fully and clearly visible ?'
          )}
        </Typography>
      </Grid>
    </VerificationContainer>
  );
}
