import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import BackendOptions from './i18n-fetch-config';
import Fetch from 'i18next-fetch-backend';
import * as Sentry from '@sentry/react';
//import LanguageDetector from "i18next-browser-languagedetector";
// don't want to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init

/**
 * List of supported languages
 */
export const SUPPORTED_LANGUAGES = [
  ['en', 'English'],
  ['cs', 'Czech'],
  ['da', 'Danish'],
  ['de', 'German'],
  ['el', 'Greek'],
  ['it', 'Italian'],
  ['es-ES', 'Spanish'],
  ['fi', 'Finnish'],
  ['fr', 'French'],
  ['hr', 'Croatian'],
  ['hu', 'Hungarian'],
  ['ja', 'Japanese'],
  ['no', 'Norwegian'],
  ['pl', 'Polish'],
  ['pt-PT', 'Portuguese'],
  ['ro', 'Romanian'],
  ['ru', 'Russian'],
  ['sk', 'Slovak'],
  ['sl', 'Slovenian'],
  ['sv-SE', 'Swedish'],
];

/**
 * Default fallback language
 */
export const DEFAULT_LANGUAGE = 'en';

/**
 * Extract language codes from SUPPORTED_LANGUAGES
 */
export const languageCodes = (): Array<string> =>
  SUPPORTED_LANGUAGES.reduce(
    (accumulator: Array<string>, item: Array<string>) => {
      accumulator.push(item[0]);
      return accumulator;
    },
    []
  );

/**
 * Transform a string into snake_case
 * @param str string
 * @returns string
 */
const langKey = (str: string): string =>
  str.toLocaleLowerCase().replace('-', '_');

/**
 * Extract country label translation
 * @param code string
 * @param label string
 * @returns string
 */
export const countryTranslation = (code: string, label: string): string => {
  return i18n.t('country_' + code, label);
};

/**
 * Get language label translation key
 * @param lang string
 * @returns string
 */
export const languageTranslation = (lang: string): string => {
  let key = DEFAULT_LANGUAGE;
  if (languageCodes().includes(lang)) {
    key = lang;
  }
  return 'lang_' + langKey(key);
};

/**
 * Associate id_types.name to a translation key
 * @param doc string
 * @returns string
 */
export const documentTranslation = (doc: string): string => {
  let key = '';
  switch (doc) {
    case 'Asylum Registration Card(Application Registration Card)':
      key = 'asylum_registration_card';
      break;
    case 'Birth Certificate *':
      key = 'birth_certificate';
      break;
    case 'Certificate of Naturalisation *':
      key = 'certificate_of_naturalisation';
      break;
    case 'Driving Licence':
      key = 'driving_licence';
      break;
    case 'Home Office Letter *':
      key = 'home_office_letter';
      break;
    case 'Immigration Status Document *':
      key = 'immigration_status_document';
      break;
    case 'National Health Insurance Card':
      key = 'national_health_insurance_card';
      break;
    case 'National ID':
      key = 'national_id';
      break;
    case 'National Identity Card':
      key = 'national_identity_card';
      break;
    case 'National Identity Card(Aadhar Card)':
      key = 'aadhar_card';
      break;
    case 'National Identity Card(HKID)':
      key = 'hkid';
      break;
    case 'National Identity Card(Individual Number Card)':
      key = 'individual_number_card';
      break;
    case 'National Identity Card(Internal Passport)':
      key = 'internal_passport';
      break;
    case 'National Identity Card(NRIC)':
      key = 'nric';
      break;
    case 'National Identity Card(RG Card)':
      key = 'rg_card';
      break;
    case 'National Identity Card(State ID)':
      key = 'state_id';
      break;
    case 'Passport':
      key = 'passport';
      break;
    case 'Passport Card':
      key = 'passport_card';
      break;
    case 'Postal Identity Card':
      key = 'postal_identity_card';
      break;
    case 'Postal Identity Card(Juminhyo)':
      key = 'juminhyo';
      break;
    case 'Professional Qualification Card':
      key = 'professional_qualification_card';
      break;
    case 'Residence Permit':
      key = 'residence_permit';
      break;
    case 'Social Security Card':
      key = 'social_security_card';
      break;
    case 'Social Security Card(Individual Number Paper Slip)':
      key = 'individual_number_paper_slip';
      break;
    case 'Tax Id':
      key = 'tax_id';
      break;
    case 'Tax Id(PAN Card)':
      key = 'pan_card';
      break;
    case 'Visa':
      key = 'visa';
      break;
    case 'Voter Id':
      key = 'voter_id';
      break;
    case 'Work Permit':
      key = 'work_permit';
      break;
    case 'Work Permit(Matrícula Consular de Alta Seguridad)':
      key = 'matrícula_consular_de_alta_seguridad';
      break;
    case 'Work Permit *':
      key = 'work_permit_2';
      break;
    default:
      key = langKey(doc);
  }
  return 'document_' + key;
};

i18n
  // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(Fetch)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  //.use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    lng: 'en',
    fallbackLng: 'en',
    preload: ['en'],
    supportedLngs: languageCodes(),
    ns: ['app'],
    defaultNS: 'app',
    fallbackNS: 'app',

    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    partialBundledLanguages: true,
    backend: BackendOptions,
  });

/**
 * Fetch HTML translation from server
 */
export const getTranslationHtml = (
  lng: string,
  filename: string,
  cb: Function
) => {
  if (!lng || !filename || !cb) {
    return;
  }
  const url = `/locales/${lng}/${filename}`;
  const headers = new Headers();
  headers.append('Content-Type', 'text/html');
  headers.append('pragma', 'no-cache');
  headers.append('cache-control', 'no-cache');
  return fetch(url, {
    method: 'get',
    headers: headers,
    redirect: 'error',
  })
    .then((response) => response.text())
    .then((responseText) => {
      // guidelines and terms and conditions do not contain html header
      if (responseText.toLocaleLowerCase().includes('<!doctype html>')) {
        throw new Error('Translation not found');
      }
      return responseText;
    })
    .then((responseText) => cb(responseText))
    .catch((error) => {
      Sentry.captureException(error);
      return getTranslationHtml('en', filename, cb);
    });
};

export default i18n;
