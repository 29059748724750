export default {
  // path where resources get loaded from, or a function
  // returning a path:
  // function(lngs, namespaces) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  loadPath: '/locales/{{lng}}/{{ns}}.json',

  // parse data after it has been fetched
  // in example use https://www.npmjs.com/package/json5
  // parse: function (data) { return data; },

  // path to post missing resources
  addPath: 'locales/add/{{lng}}/{{ns}}',

  // define how to stringify the data when adding missing resources
  stringify: JSON.stringify,

  // your backend server supports multiloading
  // /locales/resources.json?lng=de+en&ns=ns1+ns2
  allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

  multiSeparator: '+',

  // init option for fetch, for example
  requestOptions: {
    mode: 'cors',
    credentials: 'same-origin',
    cache: 'default',
  },

  // define a custom fetch function
  // fetch: function (url, options, callback) {},
};
