import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import { useVerification } from '../../../stores/verificationContext';
import { useTranslation } from 'react-i18next';
import { getTranslationHtml } from '../../../i18next';
import renderHTML from 'react-render-html';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import * as Sentry from '@sentry/react';

import createPhoneNumberSVG from '../../../assets/images/createPhoneNumber.svg';
import createPhoneNumberSVGSuccess from '../../../assets/images/createPhoneNumber_success.svg';
import createPhoneNumberSVGError from '../../../assets/images/createPhoneNumber_error.svg';
import validatePhoneNumberSVG from '../../../assets/images/validatePhoneNumber.svg';
import validatePhoneNumberSVGSuccess from '../../../assets/images/validatePhoneNumber_success.svg';
import validatePhoneNumberSVGError from '../../../assets/images/validatePhoneNumber_error.svg';
import createPhoneNumberSVGSucceeded from '../../../assets/images/createPhoneNumber_succeeded.svg';

import {
  Tooltip,
  TextField,
  Button,
  CircularProgress,
  Zoom,
  FormHelperText,
  Grow,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  createContainer: {
    margin: theme.spacing(1),
    position: 'absolute',
    top: '0',
    right: '0',
  },
  validateContainer: {
    position: 'absolute',
    top: '6px',
    right: '6px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  button: {
    fontWeight: 'bold',
    background: 'transparent',
    minWidth: '20px',
    width: '30px',
    padding: '8px 0px 10px 0px',
    opacity: '.8',

    '&:hover': {
      background: 'transparent',
      opacity: '1',
    },
  },
  buttonDisabled: {},
  placeHolderImage: {
    width: '80%',
    objectFit: 'cover',
    backgroundColor: '#ffffff',
    opacity: '.8',
    transition: 'all 1s ease',

    '&:hover': {
      opacity: '1',
    },
  },
  tokenInputContainer: {
    position: 'relative',
    marginTop: '20px',
    height: '0',
  },
  tokenInputContainerShown: {
    height: 'auto',
  },
  errorMessages: {
    maxWidth: '360px',
  },
  successMessages: {
    maxWidth: '360px',
    color: 'green',
  },
  resendToken: {
    color: '#04344c',
  },
  mismatchError: {
    color: 'red',
  },
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    right: '0',
    backgroundColor: '#ffffff',
    color: 'rgba(0, 0, 0, 0.87) ',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid var(--PhoneInputCountryFlag-borderColor)',
  },
}))(Tooltip);

const CREATE_PHONE_VERIFICATION = gql`
  mutation(
    $token: String!
    $countryId: Int!
    $phoneNumber: String
    $language: String
  ) {
    createPhoneVerification(
      hash: $token
      phone_number: $phoneNumber
      country_id: $countryId
      language: $language
    ) {
      hash
      created
      token
    }
  }
`;

const TIMEOUT_PHONE_VERIFICATION = gql`
  query($token: String) {
    timeoutPhoneVerification(hash: $token) {
      hash
      created
    }
  }
`;

export default function VerifyPhoneNumberButton({
  buttonColor,
  tokenLabel,
  tokenPlaceholder,
  onValidated,
}: {
  buttonColor: any;
  tokenLabel: any;
  tokenPlaceholder: any;
  onValidated: any;
}) {
  const {
    verificationToken,
    phoneNumber,
    phoneNumberCountry,
    selectedCountry,
  } = useVerification();

  const [createPhoneVerification, createPhoneVerificationEvent] = useMutation(
    CREATE_PHONE_VERIFICATION
  );

  const [validatePhoneVerification, validatePhoneVerificationEvent] = useState({
    loading: false,
  });

  const { t, i18n } = useTranslation(['app']);

  const classes = useStyles();

  // Fetch page HTML content form server
  const [htmlContent, setHtmlContent] = useState('loading');

  const [errorMessages, setErrorMessages] = useState({
    createPhoneVerification: '',
    validatePhoneVerification: '',
  });

  const [hasErrors, setHasErrors] = useState(false);

  const [phoneVerificationSucceeded, setPhoneVerificationSucceeded] = useState(
    verificationToken?.phone_verified
  );

  const [successMessages, setSuccessMessages] = useState({
    createPhoneVerification: '',
    validatePhoneVerification: '',
  });

  const [success, setSuccess] = useState(false);

  const [timeout, setTimeout] = useState(false);
  const [showTokenInput, setShowTokenInput] = useState(false);

  const [timeoutMax, setTimeoutMax] = useState(60);
  const [diffTimeout, setDiffTimeout] = useState(0);
  const [difference, setDifference] = useState(0);
  const [resend, setResend] = useState(false);
  const [createDisabled, setCreateDisabled] = useState(false);
  const [validateDisabled, setValidateDisabled] = useState(false);

  let createIconProps = {
    src: createPhoneNumberSVG,
  };

  let validateIconProps = {
    src: validatePhoneNumberSVG,
  };

  const [tokenValue, setTokenValue] = useState('');

  if (errorMessages.createPhoneVerification.length > 0) {
    createIconProps.src = createPhoneNumberSVGError;
  }
  if (errorMessages.validatePhoneVerification.length > 0) {
    validateIconProps.src = validatePhoneNumberSVGError;
  }

  if (successMessages.createPhoneVerification.length > 0) {
    createIconProps.src = createPhoneNumberSVGSuccess;
  }
  if (successMessages.validatePhoneVerification.length > 0) {
    validateIconProps.src = validatePhoneNumberSVGSuccess;
  }

  if (phoneVerificationSucceeded === true) {
    createIconProps.src = createPhoneNumberSVGSucceeded;
  } else {
    if (success === false) {
      createIconProps.src = createPhoneNumberSVG;
    }
  }

  const setErrorMessagesState = useCallback(
    (options) => {
      if (options.message) {
        setHasErrors(true);
      } else {
        setHasErrors(false);
      }
      setErrorMessages((prevErrorMessages) => ({
        ...prevErrorMessages,
        [options.input]: [
          t(options.translation, options.message, options.attributes),
          options.additional,
        ],
      }));
    },
    [t]
  );

  const setSuccessState = useCallback(
    (options) => {
      if (typeof options.attributes === 'undefined') {
        options.attributes = {};
      }

      setErrorMessagesState({ input: options.input, message: '' });
      if (options.message) {
        setSuccess(true);
      } else {
        setSuccess(false);
      }
      setSuccessMessages((prevSuccessMessages) => ({
        ...prevSuccessMessages,
        [options.input]: [
          t(options.translation, options.message, options.attributes),
          options.additional,
        ],
      }));
    },
    [setErrorMessagesState, t]
  );

  const [timeoutResult, setTimeoutResult] = useState({
    timeoutPhoneVerification: {
      created: '0',
    },
  });

  const [getTimeout] = useLazyQuery(TIMEOUT_PHONE_VERIFICATION, {
    variables: { token: verificationToken?.hash || '' },
    fetchPolicy: 'no-cache',
    onCompleted(data) {
      setTimeoutResult(data);
    },
    onError(err) {
      console.log('Error fetching timeout: ', err);
    },
  });

  useEffect(() => {
    getTimeout();
  }, [getTimeout]);

  const fetchTimeout = useCallback(() => {
    const timestamp =
      timeoutResult &&
      timeoutResult.timeoutPhoneVerification &&
      timeoutResult.timeoutPhoneVerification.created
        ? Number(timeoutResult.timeoutPhoneVerification.created)
        : 0;

    if (timestamp) {
      const created = new Date(timestamp * 1000);
      const timeoutAt = new Date(created.getTime() + 1000 * timeoutMax);
      const now = new Date(Date.now());
      let difference = Math.floor((timeoutAt.getTime() - now.getTime()) / 1000);

      if (difference > 0) {
        setDifference(difference);
      }

      if (difference > 0 && difference < timeoutMax) {
        setDiffTimeout(difference);
      } else if (difference > 0 && difference > timeoutMax) {
        setDiffTimeout(timeoutMax);
      }

      if (process.env.REACT_APP_VALIDATE_DEBUG === 'yes') {
        console.info('created ' + created);
        console.info('timeoutAt ' + timeoutAt);
        console.log('now ' + now);

        console.info('diff ' + diffTimeout);
        console.info('difference ' + difference);
        console.info('max ' + timeoutMax);
      }
    }
  }, [diffTimeout, timeoutMax, timeoutResult]);

  useEffect(() => {
    fetchTimeout();
  }, [fetchTimeout]);

  useEffect(() => {
    getTranslationHtml(
      i18n.language,
      'verify-phone-number.html',
      setHtmlContent
    );
  }, [i18n.language, setHtmlContent]);

  useEffect(() => {
    const validateTimeout =
      typeof process.env.REACT_APP_VALIDATE_TIMEOUT !== 'undefined'
        ? Number(process.env.REACT_APP_VALIDATE_TIMEOUT)
        : 10;
    setTimeoutMax(validateTimeout);
  }, [setTimeoutMax]);

  const createToken = () => {
    return createPhoneVerification({
      variables: {
        token: verificationToken?.hash,
        phoneNumber: phoneNumber,
        countryId: selectedCountry?.id,
        language: i18n.language,
      },
    })
      .then(function (data) {
        const result =
          data && data.data ? data.data.createPhoneVerification : {};

        if (result.hash) {
          setShowTokenInput(true);
          setSuccessState({
            input: 'createPhoneVerification',
            translation: 'app:successCreatePhoneVerification',
            message: 'Phone verification initialized.',
          });

          if (process.env.REACT_APP_VALIDATE_DEV === 'auto') {
            //validateToken(result.token);
          }
          if (process.env.NODE_ENV === 'development') {
            console.log('Dev only token: ' + result.token);
          }

          setTimeout(true);
        }
      })
      .catch((err) => {
        Sentry.captureException(err);
        console.error(err);
        setSuccess(false);
        setErrorMessagesState({
          input: 'createPhoneVerification',
          translation: 'app:errorCreatePhoneVerification',
          message: 'Phone verification token creation error.',
        });
      });
  };

  const wordNew = t('app:new', 'new');

  useEffect(() => {
    let timer = window.setTimeout(() => {
      if (diffTimeout > 0) {
        setDiffTimeout(diffTimeout - 1);
      } else {
        window.clearTimeout(timer);
      }

      if (phoneVerificationSucceeded === true) {
        setTimeout(false);
        setShowTokenInput(false);
        setCreateDisabled(true);

        setSuccessState({
          input: 'createPhoneVerification',
          translation: 'app:successPhoneVerification',
          message: 'Phone number verified.',
        });
      }
    }, 1000);
  }, [diffTimeout, phoneVerificationSucceeded, setSuccessState]);

  const validatePhoneNumber = async (phoneNumber) => {
    let response;

    try {
      validatePhoneVerificationEvent({ loading: true });

      let verification = await fetch(
        `${process.env.REACT_APP_URI}/api/phone-number-verification/${verificationToken?.hash}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            phone_number: phoneNumber,
            country: phoneNumberCountry,
          }),
        }
      ).catch(function (error) {
        validatePhoneVerificationEvent({ loading: false });

        setErrorMessagesState({
          input: 'createPhoneVerification',
          translation: 'app:errorPhoneValidationInvalidNumber',
          message:
            'The number provided is not a valid mobile number. Please try again.',
        });
        console.error('Error verifying phone number.', error);

        return Promise.reject(error.message || error);
      });

      const responseJson = await verification.json();
      response = responseJson;

      if (typeof response.valid !== 'undefined') {
        if (response.valid !== true) {
          validatePhoneVerificationEvent({ loading: false });

          if (response.error === 'COUNTRY_DISABLED') {
            setErrorMessagesState({
              input: 'createPhoneVerification',
              translation: 'app:errorPhoneValidationDisabledCountry',
              message:
                'The number provided is located in an unsupported country. Contact us for more information.',
            });
          } else {
            setErrorMessagesState({
              input: 'createPhoneVerification',
              translation: 'app:errorPhoneValidationInvalidNumber',
              message:
                'The number provided is not a valid mobile number. Please try again.',
            });
          }
        } else {
          validatePhoneVerificationEvent({ loading: false });
        }
      }

      // check for error response
      if (!verification.ok) {
        // get error message from body or default to response status
        const error =
          (responseJson && responseJson.message) || verification.status;
        return Promise.reject(error);
      }
    } catch (error) {
      console.error('Error verifying phone number.', error);
    }

    return response;
  };

  const onCreateClick = async () => {
    if (diffTimeout > 0 && difference >= timeoutMax) {
      return;
    }

    validatePhoneNumber(phoneNumber).then(async (response) => {
      if (
        typeof response !== 'undefined' &&
        typeof response.valid !== 'undefined' &&
        response.valid === true
      ) {
        createToken();
      }
    });
  };

  const onCreateSubmit = () => {};

  useEffect(() => {
    if (verificationToken?.phone_number !== phoneNumber) {
      setPhoneVerificationSucceeded(false);
      setCreateDisabled(false);
      setSuccess(false);
    }
  }, [verificationToken, phoneNumber]);

  const validateToken = (token = tokenValue) => {
    let tokenVal = token.replace(/[^0-9a-zA-Z]/gi, '');
    let tokenInputValue = (
      tokenVal.slice(0, 3) +
      ' ' +
      tokenVal.slice(3, 6)
    ).toUpperCase();
    setTokenValue(tokenInputValue);

    if (tokenVal.length === 6) {
      fetch(
        `${process.env.REACT_APP_URI}/api/phone-validation/${verificationToken?.hash}`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            token: tokenVal,
          }),
        }
      )
        .then(async (response) => {
          const results = await response.json();
          if (
            results.some((error) => error.type === 'PHONE_VALIDATION_MISMATCH')
          ) {
            setErrorMessagesState({
              input: 'validatePhoneVerification',
              translation: 'app:errorPhoneValidationMismatch',
              message: 'The tokens mismatch. Please try again.',
            });
          }

          if (results.some((success) => success.verified === 1)) {
            setPhoneVerificationSucceeded(true);
            setTimeout(false);
            setShowTokenInput(false);
            setCreateDisabled(true);
            setValidateDisabled(true);

            setSuccessState({
              input: 'createPhoneVerification',
              translation: 'app:successPhoneVerification',
              message: 'Phone number verified.',
            });

            results.some((success) => onValidated(success));
          } else {
            setValidateDisabled(false);
          }

          // check for error response
          if (!response.ok) {
            // get error message from body or default to response status
            const error = (results && results.message) || response.status;
            return Promise.reject(error);
          }
        })
        .catch((error) => {
          console.error('Error verifying phone number.', error);
        });
    } else {
      setErrorMessagesState({
        input: 'validatePhoneVerification',
        translation: 'app:errorPhoneValidationLength',
        message: 'The tokens needs to be at least 6 characters long.',
      });
    }
  };

  const onValidateKeyUp = (event) => {
    validateToken(event.target.value);
  };

  const onValidateSubmit = () => {
    validateToken();
  };

  return (
    <React.Fragment>
      <div className={classes.createContainer}>
        <HtmlTooltip
          TransitionComponent={Zoom}
          enterDelay={500}
          leaveDelay={200}
          title={<React.Fragment>{renderHTML(htmlContent)}</React.Fragment>}
          arrow
        >
          <span>
            <Button
              className={`${classes.button} ${
                createDisabled ? classes.buttonDisabled : ''
              }`}
              color={buttonColor}
              onClick={showTokenInput ? onCreateSubmit : onCreateClick}
              disabled={createDisabled}
            >
              {!createPhoneVerificationEvent.loading && (
                <img
                  {...createIconProps}
                  className={classes.placeHolderImage}
                  alt={''}
                />
              )}
              {createPhoneVerificationEvent.loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </span>
        </HtmlTooltip>
      </div>

      {hasErrors && errorMessages.createPhoneVerification && (
        <React.Fragment>
          <FormHelperText error={true} className={classes.errorMessages}>
            {errorMessages.createPhoneVerification}
          </FormHelperText>
        </React.Fragment>
      )}

      {success && successMessages.createPhoneVerification && (
        <FormHelperText className={classes.successMessages}>
          {successMessages.createPhoneVerification}
        </FormHelperText>
      )}

      {timeout && diffTimeout > 0 && (
        <FormHelperText className={classes.successMessages}>
          {t(
            'app:timeoutPhoneVerification',
            'An SMS with a {{new}} token should arrive withing {{seconds}} seconds.',
            {
              seconds: diffTimeout.toString(),
              new: resend ? wordNew : '',
            }
          )}
        </FormHelperText>
      )}

      {timeout && diffTimeout <= 0 && (
        <FormHelperText className={classes.successMessages}>
          {t(
            'app:resendPhoneVerification',
            'If you did not receive an SMS you can resend the token.'
          )}{' '}
          {t('app:resendPhoneVerificationLinkBefore', 'To do so please click ')}
          <Link
            className={classes.resendToken}
            to="#"
            onClick={() => {
              setResend(true);
              createToken().then(async (response) => {
                setDiffTimeout(timeoutMax);
              });
            }}
          >
            {t('app:resendPhoneVerificationLinkText', 'here')}
          </Link>
          {t('app:resendPhoneVerificationLinkAfter', '')}
        </FormHelperText>
      )}

      <Grow in={showTokenInput}>
        <div
          className={`${
            showTokenInput ? classes.tokenInputContainerShown : ''
          } ${classes.tokenInputContainer}`}
        >
          <TextField
            style={{ width: '100%' }}
            label={tokenLabel}
            variant="outlined"
            onKeyUp={onValidateKeyUp}
            onChange={(event) => setTokenValue(event.target.value)}
            placeholder={tokenPlaceholder}
            value={tokenValue}
          />

          <div className={classes.validateContainer}>
            <Button
              className={`${classes.button} ${
                validateDisabled ? classes.buttonDisabled : ''
              }`}
              color="primary"
              onClick={onValidateSubmit}
              disabled={validateDisabled}
            >
              {!validatePhoneVerification.loading && (
                <img
                  {...validateIconProps}
                  className={classes.placeHolderImage}
                  alt={''}
                />
              )}
              {validatePhoneVerification.loading && (
                <CircularProgress
                  size={24}
                  className={classes.buttonProgress}
                />
              )}
            </Button>
          </div>

          {hasErrors && errorMessages.validatePhoneVerification && (
            <React.Fragment>
              <FormHelperText error={true} className={classes.errorMessages}>
                {errorMessages.validatePhoneVerification}
              </FormHelperText>
            </React.Fragment>
          )}

          {success && successMessages.validatePhoneVerification && (
            <FormHelperText className={classes.successMessages}>
              {successMessages.validatePhoneVerification}
            </FormHelperText>
          )}
        </div>
      </Grow>
    </React.Fragment>
  );
}
