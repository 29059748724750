export class DeviceNotSupported extends Error {
  constructor() {
    super('Device not supported');
    this.name = 'Device not Supported';
  }
}

export class CameraPermissionsDenied extends Error {
  constructor() {
    super('Camera Permissions Denied');
    this.name = 'Camera Permissions Denied';
  }
}

export class NoWebcam extends Error {
  constructor() {
    super('Device has no webcam');
    this.name = 'Device has no webcam';
  }
}
