import React from 'react';
import ReturnToSiteButton from '../../components/returnToSiteButton';
import { Typography } from '@material-ui/core';

function ApprovedScreen() {
  return (
    <React.Fragment>
      <Typography variant="h1" color={'primary'} align={'center'}>
        Verification Approved
      </Typography>
      <ReturnToSiteButton />
    </React.Fragment>
  );
}

export default ApprovedScreen;
