import React, { useEffect, useState } from 'react';
import {
  Box,
  FormControlLabel,
  Grid,
  RadioGroup,
  Radio,
  TextField,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { useVerification } from '../../../stores/verificationContext';
import VerificationContainer from '../container/index.js';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import MuiPhoneNumber from 'material-ui-phone-number';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
// import PhoneInput from 'react-phone-number-input';
// import CustomPhoneNumber from '../../PhoneNumber';
import 'react-phone-number-input/style.css';
import './styles.css';
import { makeStyles } from '@material-ui/core/styles';
import VerifyPhoneNumberButton from '../verifyPhoneNumberButton/index.js';

import { useTranslation } from 'react-i18next';
// import { copyFileSync } from 'fs';

type GeneralInfoProps = {
  next: Function;
};

const useStyles = makeStyles((theme) => ({
  autoWidth: {
    width: '320px',
    '@media only screen and (max-device-width: 320px)': {
      width: '100%',
    },
  },
}));

export default function GeneralInfo({ next }: GeneralInfoProps) {
  const { t } = useTranslation(['app']);

  const {
    verificationToken,
    firstName,
    setFirstName,
    middleName,
    setMiddleName,
    lastName,
    setLastName,
    email,
    setEmail,
    birthday,
    setBirthday,
    gender,
    setGender,
    phoneNumber,
    setPhoneNumber,
    phoneNumberVerified,
    setPhoneNumberVerified,
    setPhoneNumberCountry,
  } = useVerification();

  const [errorMessages, setErrorMessages] = useState({
    firstName: '',
    lastName: '',
    birthday: '',
  });

  const classes = useStyles();
  const [hasErrors, setHasErrors] = useState(false);
  const [manualInput, setManualInput] = useState(false);

  const onFirstNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setFirstName(event.target.value);
    setManualInput(true);
    if (event.target.value.length > 2) {
      setErrorMessagesState('firstName', '');
    }
  };

  const onFirstNameBlur = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length < 3) {
      setHasErrors(true);
      setErrorMessagesState(
        'firstName',
        t(
          'app:generalInfoFormFirstNameErrorsInvalid',
          'First name must be a minimum of two characters long'
        )
      );
    }
  };

  const setErrorMessagesState = (input, message) => {
    if (message) {
      setHasErrors(true);
    } else {
      setHasErrors(false);
    }
    setErrorMessages((prevErrorMessages) => ({
      ...prevErrorMessages,
      [input]: message,
    }));
  };

  const onMiddleNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMiddleName(event.target.value);
    setManualInput(true);
  };

  const onLastNameChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setLastName(event.target.value);
    setManualInput(true);
    if (event.target.value.length > 2) {
      setErrorMessagesState('lastName', '');
    }
  };

  const onLastNameBlur = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (event.target.value.length < 3) {
      setHasErrors(true);
      setErrorMessagesState(
        'lastName',
        t(
          'app:generalInfoFormLastNameErrorsInvalid',
          'Last name must be a minimum of two characters long'
        )
      );
    }
  };

  const onEmailChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setEmail(event.target.value);
    setManualInput(true);
  };

  const onBirthdayChange = (date: MaterialUiPickersDate) => {
    if (date) {
      const age = moment().diff(date, 'years');
      setBirthday(date);
      if (age < 18) {
        setErrorMessagesState(
          'birthday',
          t(
            'app:generalInfoFormBirthdayErrorsUnderage',
            'If you are under the age of 18 you cannot use this service'
          )
        );
      } else if (age >= 100) {
        setErrorMessagesState(
          'birthday',
          t(
            'app:generalInfoFormBirthdayErrorsInvalid',
            'Enter a correct date of birth'
          )
        );
      } else {
        setErrorMessagesState('birthday', '');
      }
      setManualInput(true);
    }
  };

  const onPhoneNumberChange = (phoneNumber: string, country: object) => {
    setPhoneNumberCountry(country);
    setPhoneNumber(phoneNumber);
    setManualInput(true);
  };

  const onGenderChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender(event.target.value);
    setManualInput(true);
  };

  const onPhoneNumberValidated = (result) => {
    if (typeof result.verified !== 'undefined' && result.verified === 1) {
      setPhoneNumberVerified(result.verified);
    }
  };

  const validate = () => {
    next();
  };

  const [validateEnabled, setValidateEnabled] = useState(false);

  useEffect(() => {
    if (typeof process.env.REACT_APP_VALIDATE_ENABLE !== 'undefined') {
      const VALIDATE_ENABLE = process.env.REACT_APP_VALIDATE_ENABLE === 'true';
      setValidateEnabled(VALIDATE_ENABLE);
    }
  }, []);

  const inputValidated = () => {
    if (firstName && lastName && birthday && gender && email && !hasErrors) {
      if (
        verificationToken?.phone_number_required === true &&
        ((validateEnabled && !phoneNumberVerified) ||
          !phoneNumber ||
          phoneNumber.length < 6)
      ) {
        return false;
      }
      if (!firstName.match(/\D+/) || !lastName.match(/\D+/)) {
        return false;
      }
      return true;
    }
    return false;
  };

  if (inputValidated() && verificationToken?.has_parent && !manualInput) {
    // next();
  }

  return (
    <VerificationContainer
      next={validate}
      headerText={t('app:generalInfoTitle', 'General information')}
      captionText={''}
      buttonText={t('app:buttonContinue', 'Continue')}
      buttonDisabled={!inputValidated()}
    >
      <Grid
        container
        spacing={2}
        direction="column"
        justify="flex-start"
        alignItems="center"
      >
        <Grid item className={classes.autoWidth}>
          <Box mb={2}>
            <TextField
              style={{ width: '100%' }}
              label={t('app:generalInfoFormFirstNameLabel', 'First name')}
              onChange={(e) => onFirstNameChange(e)}
              onBlur={(e) => onFirstNameBlur(e)}
              value={firstName ?? ''}
              variant="outlined"
            />
            {errorMessages.firstName && (
              <FormHelperText error={true} style={{ maxWidth: '240px' }}>
                {errorMessages.firstName}
              </FormHelperText>
            )}
          </Box>
          <Box mb={2}>
            <TextField
              style={{ width: '100%' }}
              label={t('app:generalInfoFormMiddleNameLabel', 'Middle name')}
              onChange={(e) => onMiddleNameChange(e)}
              value={middleName ?? ''}
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <TextField
              style={{ width: '100%' }}
              label={t('app:generalInfoFormLastNameLabel', 'Last name')}
              onChange={(e) => onLastNameChange(e)}
              onBlur={(e) => onLastNameBlur(e)}
              value={lastName ?? ''}
              variant="outlined"
            />
            {errorMessages.lastName && (
              <FormHelperText error={true} style={{ maxWidth: '240px' }}>
                {errorMessages.lastName}
              </FormHelperText>
            )}
          </Box>
          <Box mb={2}>
            <TextField
              style={{ width: '100%' }}
              label={t('app:generalInfoFormEmailLabel', 'Email')}
              onChange={(e) => onEmailChange(e)}
              value={email ?? ''}
              variant="outlined"
            />
          </Box>
          <Box mb={2}>
            <MuiPickersUtilsProvider utils={MomentUtils}>
              <DatePicker
                variant="inline"
                format="DD-MM-yyyy"
                style={{ width: '100%' }}
                inputVariant={'outlined'}
                autoOk={true}
                value={birthday}
                label={t('app:generalInfoFormBirthdayLabel', 'Date of birth')}
                onChange={(date) => onBirthdayChange(date)}
                disableFuture={true}
              />
            </MuiPickersUtilsProvider>
            {errorMessages.birthday && (
              <FormHelperText error={true} style={{ maxWidth: '240px' }}>
                {errorMessages.birthday}
              </FormHelperText>
            )}
          </Box>
          <Box
            display={'flex'}
            mb={2}
            alignItems={'center'}
            justifyContent={'center'}
          >
            <FormControl component="fieldset">
              {/*<FormLabel component="legend">Gender</FormLabel>*/}
              <RadioGroup
                row
                aria-label="gender"
                name="gender1"
                value={gender}
                onChange={onGenderChanged}
              >
                <FormControlLabel
                  value="F"
                  control={<Radio />}
                  label={t('app:generalInfoFormValuesFemale', 'Female')}
                />
                <FormControlLabel
                  value="M"
                  control={<Radio />}
                  label={t('app:generalInfoFormValuesMale', 'Male')}
                />
              </RadioGroup>
            </FormControl>
          </Box>

          {/*<Box mb={2}>*/}
          {/*  <PhoneInput*/}
          {/*      value={phoneNumber}*/}
          {/*      onChange={onPhoneNumberChange}*/}
          {/*      inputComponent={CustomPhoneNumber}*/}
          {/*  />*/}
          {/*</Box>*/}
          {verificationToken?.phone_number_required === true && (
            <Box mb={2} style={{ position: 'relative' }}>
              <MuiPhoneNumber
                id="phoneNumberInput"
                style={{ width: '100%' }}
                disableAreaCodes={true}
                value={phoneNumber}
                defaultCountry={'us'}
                variant="outlined"
                disabled={verificationToken.phone_verified}
                label={t('app:generalInfoFormPhoneNumberLabel', 'Phone number')}
                onChange={onPhoneNumberChange}
              />

              {validateEnabled && (
                <VerifyPhoneNumberButton
                  onValidated={onPhoneNumberValidated}
                  buttonColor="primary"
                  tokenLabel={t('app:phoneVerificationTokenLabel', 'Token')}
                  tokenPlaceholder={t(
                    'app:phoneVerificationTokenPlaceholder',
                    'ABC 123'
                  )}
                />
              )}
            </Box>
          )}
        </Grid>
      </Grid>
    </VerificationContainer>
  );
}
