import axios, { AxiosResponse } from 'axios';
import { VerificationToken } from '../screens/main/types';

const textract = function (
  token: VerificationToken | null,
  blob: Blob,
  searches: Object
): Promise<AxiosResponse> {
  const searchFields = ['firstName', 'middleName', 'lastName', 'country'];
  // Build form data
  const formData = new FormData();
  formData.append('photo', blob);
  searchFields.forEach(function (key) {
    if (typeof searches[key] === 'string') {
      formData.append(key, searches[key]);
    }
  });

  return axios.post(
    `${process.env.REACT_APP_URI}/api/submit/${token?.hash}/analyze`,
    formData
  );
};

export default textract;
