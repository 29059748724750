import React from 'react';
import { makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import QRCode from 'qrcode.react';
import logo from '../../../assets/images/logo.png';
import NextVerificationButton from '../nextButton';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  qrCode: {
    borderRadius: 0,
    display: 'block',
    margin: '20px auto',
    maxWidth: '100%',
    objectFit: 'cover',
  },
}));

type QRCodeStepProps = {
  data: string;
  goBack: Function;
};

export default function QRCodeStep({ data, goBack }: QRCodeStepProps) {
  const { t } = useTranslation(['app']);
  const classes = useStyles();
  const handleGoBack = () => goBack();

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justify="flex-start"
      alignItems="center"
    >
      <Grid item sm={12}>
        <img src={logo} alt="evalid" height={70} />
      </Grid>

      <Grid item sm={12}>
        <QRCode
          value={data}
          size={300}
          renderAs="svg"
          level="H"
          className={classes.qrCode}
        />
      </Grid>

      <Grid item>
        <NextVerificationButton
          text={t('app:btnBack', 'Back')}
          onClick={handleGoBack}
          disabled={false}
        />
      </Grid>
    </Grid>
  );
}
