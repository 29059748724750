enum VerificationStatus {
  new = 'NEW',
  pending = 'PENDING',
  submitted = 'SUBMITTED',
  approved = 'PASSED',
  rejected = 'REJECTED',
  unknown = 'UNKNOWN',
  notFound = 'NOT_FOUND',
}

class AppState {
  hash: string = '';
  status: VerificationStatus = VerificationStatus.unknown;
  redirectUrl: string = '';
}

export { VerificationStatus, AppState };
