import React from 'react';
import Typography from '@material-ui/core/Typography';
import ReturnToSiteButton from '../../components/returnToSiteButton';
import ThankYouSVG from '../../assets/images/thx.svg';
import { videoWrapperStyles } from '../../components/cameraPreview/styles';

import { useTranslation } from 'react-i18next';

export default function ThankYou() {
  const { t } = useTranslation(['app']);

  const classes = videoWrapperStyles();

  return (
    <React.Fragment>
      <Typography variant="h4" gutterBottom align={'center'}>
        {t('app:thankYouTitle', 'Thank you')}
      </Typography>
      <Typography variant="subtitle1" gutterBottom align={'center'}>
        {t('app:thankYouText', 'Your data has been submitted.')}
      </Typography>
      <img src={ThankYouSVG} className={classes.placeHolderImage} alt={''} />
      <ReturnToSiteButton />
    </React.Fragment>
  );
}
