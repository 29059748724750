import React, { useContext, useEffect } from 'react';
import { videoStreamStore } from '../../../stores/videoContext';
import VerificationContainer from '../container/index.js';
import AllowCameraUseError from '../../cameraPermissionsError';
import { videoWrapperStyles } from '../../cameraPreview/styles';

import { useVerification } from '../../../stores/verificationContext';
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from '@material-ui/core';

type ReviewDocumentBackPhotoProps = {
  next: Function;
  prev: Function;
};

export default function ReviewDocumentBackPhoto({
  next,
  prev,
}: ReviewDocumentBackPhotoProps) {
  const { t } = useTranslation(['app']);

  const { videoStream, startVideoStream, videoStreamError } = useContext(
    videoStreamStore
  );

  const { documentBackPhotoBlob } = useVerification();
  let documentPhotoImg = '';

  if (
    documentBackPhotoBlob.size > 0 &&
    documentBackPhotoBlob.type === 'image/jpeg'
  ) {
    documentPhotoImg = URL.createObjectURL(documentBackPhotoBlob);
  }

  const classes = videoWrapperStyles();

  useEffect(() => {
    startVideoStream(false);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const goForward = () => {
    next();
  };

  const goBack = () => {
    prev();
  };

  return (
    <VerificationContainer
      next={goForward}
      prev={goBack}
      prevButtonText={t('app:noRetake', 'No, retake')}
      headerText={t(
        'app:reviewDocumentBackPhotoTitle',
        'Back side of your identity document'
      )}
      buttonText={t('app:yesContinue', 'Yes, continue')}
      buttonDisabled={!videoStream}
      stretch
    >
      {videoStreamError && <AllowCameraUseError />}
      {documentPhotoImg && (
        <img
          src={documentPhotoImg}
          className={classes.placeHolderImage}
          alt={''}
        />
      )}
      <Grid alignItems={'center'} alignContent={'center'}>
        <Typography>
          {t(
            'app:isTheDocumentFullyVisibleAndDataClearlyReadable',
            'Is the document fully visible and data clearly readable ?'
          )}
        </Typography>
      </Grid>
    </VerificationContainer>
  );
}
