import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import { Link } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { VerificationTokenContext } from '../../screens/main/VerificationTokenContext';
import { makeStyles } from '@material-ui/core/styles';

import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  button: {
    fontWeight: 'bold',
    color: 'white',
    backgroundColor: '#63bc96',
    textTransform: 'none',
  },
}));

export default function ReturnToSiteButton() {
  const { t } = useTranslation(['app']);
  const { verification } = useContext(VerificationTokenContext);
  const classes = useStyles();

  return (
    <Typography variant="h1" color={'primary'} align={'center'}>
      <Link href={verification?.redirect_url} variant="subtitle1">
        <Button variant="contained" color="primary" className={classes.button}>
          {t('app:buttonBackToSite', 'Back to site')}
        </Button>
      </Link>
    </Typography>
  );
}
