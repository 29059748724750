import React, { useContext, useEffect } from 'react';

import SplashScreen from '../../../screens/spalsh';
import { videoStreamStore } from '../../../stores/videoContext';
import { Box, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CopyToClipboard from '../../copyToClipboard';
import { isiOS } from '../../../stores/videoContext/utils';
import { NoWebcam } from '../../../stores/videoContext/errors';

type CompatibilityTestProps = {
  next: Function;
};

export default function CompatibilityTest({ next }: CompatibilityTestProps) {
  const { checkCompatibility, videoStreamError } = useContext(videoStreamStore);
  const [checkFailed, setCheckFailed] = React.useState(false);

  useEffect(() => {
    if (checkCompatibility()) {
      next();
    } else {
      setCheckFailed(true);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (checkFailed || videoStreamError instanceof Error) {
    let title = 'Continue with another browser';
    let primaryMessage =
      'Please copy the URL below, open Chrome, Firefox or Safari and paste it into the address bar to continue';
    let secondaryMessage =
      'Please make sure your device has a camera and the browser is allowed to use it';
    if (isiOS()) {
      title = 'iOS version outdated';
      primaryMessage =
        'Please make sure your iOS device runs on at least iOS 14.8';
      secondaryMessage =
        "If you can't update your iOS device, you can open this link on another device which a working camera";
    }
    if (videoStreamError instanceof NoWebcam) {
      title = 'No Camera';
      primaryMessage =
        'Please open this link on another device which has a working camera.';
      secondaryMessage = '';
    }

    return (
      <Grid container direction="column" justify="space-between">
        <Grid item>
          <Box ml={4} mr={4}>
            <Typography variant={'h4'} align={'center'}>
              {title}
            </Typography>
            <br />
            <Typography align={'center'}>{primaryMessage}</Typography>
            <br />
            <Typography align={'center'}>{secondaryMessage}</Typography>
            <br />
          </Box>
        </Grid>
        <Grid container item xs={12} justify={'center'}>
          <Grid item xs={12} sm={8}>
            <Box p={3}>
              <CopyToClipboard>
                {({ copy }) => (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => copy(window.location.href)}
                    fullWidth
                  >
                    Copy URL
                  </Button>
                )}
              </CopyToClipboard>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return <SplashScreen />;
}
